<template>
  <div id="perfil">
    <ModalAlterarSenha />
    <ModalEditarPerfil />
    <SectionTopo />
    <SectionPerfil />
    <SectionOpcoes />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { useStorePerfil } from '@stores'

import ModalAlterarSenha from '@components/app/perfil/modals/ModalAlterarSenha.vue'
import ModalEditarPerfil from '@components/app/perfil/modals/ModalEditarPerfil.vue'
import SectionTopo from '@components/app/perfil/partials/SectionTopo.vue'
import SectionPerfil from '@components/app/perfil/partials/SectionPerfil.vue'
import SectionOpcoes from '@components/app/perfil/partials/SectionOpcoes.vue'
import Footer from '@components/global/footer/Footer.vue'

const storePerfil = useStorePerfil()
const emitter = inject('emitter')

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

onMounted(() => {
  carregarPerfil()
  emitter.on('atualizarPerfil', carregarPerfil)
})
</script>

<style scoped></style>
