<template>
  <div class="linkTarget">
    <h3>Abertura do link</h3>
    <div class="botoes">
      <button @click="selecionarTarget('_blank')" class="um" :class="{ ativo: state.targetSelecionado === '_blank' }">
        <Svgs nome="target-blank" />
        <p>Abrir em nova aba</p>
      </button>
      <button @click="selecionarTarget('_self')" :class="{ ativo: state.targetSelecionado === '_self' }">
        <Svgs nome="target-self" />
        <p>Abrir na mesma aba</p>
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
import Svgs from '@svgs'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  targetSelecionado: ''
})

function selecionarTarget(target) {
  state.targetSelecionado = target
  elementoSelecionado().linkTarget = target
}

function aplicarTargetSelecionado() {
  const target = elementoSelecionado().linkTarget
  selecionarTarget(target)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  aplicarTargetSelecionado()
})
</script>

<style scoped>
.linkTarget {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}
h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}
.botoes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}
.botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  width: 100%;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}
.botoes button.ativo {
  background-color: var(--cor-cinza-4);
}
.botoes button.um {
  margin: 0 0 10px 0;
}
.botoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  text-align: left;
  transition: all 0.3s;
}
.botoes button.ativo p {
  color: var(--cor-branco);
}
.botoes button:hover p {
  color: var(--cor-branco);
}
.botoes button svg {
  width: 20px;
  min-width: 20px;
  max-height: 15px;
  margin: 0 5px 0 0;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}
.botoes button:hover svg {
  fill: var(--cor-branco);
}
.botoes button.ativo svg {
  fill: var(--cor-branco);
}
</style>
