<template>
  <section class="apresentacao" id="funcionalidades">
    <div class="texto">
      <h2>Tantas facilidades que você vai criar sites com um sorriso no rosto 😃</h2>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Configure seu domínio em segundos</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Crie versões mobile e desktop do seu site</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Tenha visitas ilimitadas</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Integre com ferramentas como Google e Facebook</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Diversos templates prontos</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Duplique sites em segundos</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Certificado SSL já ativo em todos os sites</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Backup 24h em todos os seus sites</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Cursos, tutoriais e PDFs para ajudar na criação de sites</p>
      </div>
      <div class="tag">
        <div class="toggle">
          <span></span>
        </div>
        <p>Integre seus formulários de forma ilimitada</p>
      </div>
      <div class="tag">
        <div class="toggle off">
          <span></span>
        </div>
        <p>Só falta você começar a usar a tecnologia Unic</p>
      </div>
    </div>

    <div class="quadros">
      <div class="itens">
        <div class="quadro">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-quadro-like.svg" />
          <h3>Configuração Fácil</h3>
          <p>Configure seu domínio apenas adicionando um registro A</p>
        </div>
        <div class="quadro">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-quadro-cursor.svg" />
          <h3>Seu domínio é seu</h3>
          <p>Não gerenciamos domínio. Só criamos um registro no seu DNS</p>
        </div>
        <div class="quadro">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-quadro-servidor.svg" />
          <h3>Hospedagem</h3>
          <p>Seu site já está hospedado e ativo na UnicPages</p>
        </div>
        <div class="quadro">
          <img src="https://arquivos.unicpages.com/imagens/web/site/icone-quadro-cadeado.svg" />
          <h3>Certificado SSL</h3>
          <p>Seu certificado SSL já está ativo e funcionando no seu site Unic</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { onMounted, onUnmounted } from 'vue'

function scrollToBottom() {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth'
  })
}

function handleClick(event) {
  if (event.target.closest('.toggle') || event.target.closest('.quadro')) {
    scrollToBottom()
  }
}

onMounted(() => {
  document.addEventListener('click', handleClick)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClick)
})
</script>

<style scoped>
section {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  background: var(--cor-cinza-1);
  padding: 80px;
  position: relative;
  z-index: 4;
}

section .quadros {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  box-sizing: border-box;
}

section .itens {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
  padding: 30px 30px 10px 30px;
  box-sizing: border-box;
}

section .itens .quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  box-sizing: border-box;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: calc(50% - 10px);
  padding: 30px;
  margin: 0 0 20px 0;
  transition: all 0.3s;
}

section .itens .quadro:hover {
  background-color: var(--cor-cinza-2);
  border: 1px solid var(--cor-azul);
}

section .itens .quadro img {
  width: 100%;
  max-width: 60px;
  margin: 0;
  padding: 0;
}

section .itens .quadro h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 20px 0 10px 0;
  padding: 0;
}

section .itens .quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 0;
  padding: 0;
}

section .quadros .botao {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 30px 30px 30px;
  box-sizing: border-box;
}

section .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 50%;
}

section .texto h2 {
  font-family: var(--bold);
  font-size: var(--f6);
  color: #ffffff;
  line-height: 1.2;
  margin: 0 0 20px 0;
  max-width: 500px;
}

section .texto .tag {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  box-sizing: border-box;
  margin: 20px 0 0 0;
}

section .texto .tag .toggle {
  display: flex;
  position: relative;
  width: 40px;
  height: 20px;
  border-radius: 50px;
  background-color: var(--cor-azul);
  margin: 0 15px 0 0;
  transition: all 0.3s;
}

section .texto .tag .toggle.off {
  background-color: var(--cor-cinza-3);
}

section .texto .tag .toggle.off span {
  right: auto;
  left: 0;
}

section .texto .tag .toggle span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-color: var(--cor-branco-fixo);
  position: absolute;
  right: 0;
  top: 0;
  transition: all 0.3s;
}

section .texto .tag:hover .toggle.off span {
  left: 20px;
}

section .texto .tag:hover .toggle {
  background-color: var(--cor-verde);
}

section .texto .tag p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}
@media screen and (max-width: 1024px) {
  section {
    flex-direction: column;
    align-items: flex-start;
    padding: 40px 20px;
  }

  section .quadros {
    margin: 40px 0 0 0;
  }

  section .itens {
    padding: 15px 15px 5px 15px;
  }

  section .itens .quadro {
    width: calc(50% - 5px);
    padding: 20px 15px;
    margin: 0 0 10px 0;
  }

  section .itens .quadro img {
    max-width: 50px;
  }

  section .itens .quadro h3 {
    font-size: var(--f0);
    margin: 20px 0 10px 0;
  }

  section .itens .quadro p {
    font-size: var(--f0);
  }

  section .texto {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  section .texto h2 {
    font-size: var(--f4);
    line-height: 1.3;
    margin: 0;
  }

  section .texto .tag {
    margin: 15px 0 0 0;
  }

  section .texto .tag .toggle {
    width: 20px;
    height: 10px;
    margin: 0 10px 0 0;
  }

  section .texto .tag .toggle span {
    width: 10px;
    height: 10px;
  }

  section .texto .tag:hover .toggle.off span {
    left: 15px;
  }

  section .texto .tag p {
    font-size: var(--f0);
  }
}
</style>
