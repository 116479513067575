<template>
  <Modal nome="onboarding">
    <h3>Olá {{ storePerfil.usuario?.nome.split(' ')[0] }}, essa é a sua Unic 🏆</h3>
    <p>Criamos um vídeo especial para você aprender TUDO sobre sua ferramenta de criação em apenas 2 minutos. Assista e comece a criar sites incríveis!</p>
    <div class="video">
      <div class="tamanho">
        <iframe src="https://www.youtube.com/embed/KwJmC8s972s?si=H3vUTR9h-Q4nZRr3" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
      </div>
    </div>
    <BotaoSalvar nome="Começar a criar" @click="fecharModal" />
  </Modal>
</template>
<script setup>
import { onMounted } from 'vue'
import { useStorePerfil, useStoreModal, useStoreRemoteStorage } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const storePerfil = useStorePerfil()
const storeModal = useStoreModal()
const storeRemoteStorage = useStoreRemoteStorage()

async function verificarRemoteStorage() {
  const onboarding = await storeRemoteStorage.receberOnboarding()
  if (typeof onboarding?.visto === 'boolean' && onboarding?.visto === true) return

  storeModal.mostrarModal('onboarding', 'estrela', 'Boas-vindas à UnicPages')
  storeRemoteStorage.alterarOnboarding({ visto: true })
}

function fecharModal() {
  storeModal.fecharModal('onboarding')
}

onMounted(() => {
  verificarRemoteStorage()
})
</script>

<style scoped>
h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco);
  width: 100%;
  text-align: center;
  line-height: 1.5;
}

p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 30px 0;
  width: 100%;
  text-align: center;
}

button {
  display: flex;
  align-items: center;
  background-color: var(--cor-azul);
  padding: 20px 30px;
  border-radius: 50px;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  margin: 30px 0 0 0;
  transition: all 0.3s;
}

button:hover {
  background-color: var(--cor-verde);
}

button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

.video {
  width: 100%;
}

.video .tamanho {
  position: relative;
  width: 100%;
  padding-bottom: 56.52%;
}

.video .tamanho iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--cor-preto);
  border-radius: 20px;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  h3 {
    font-size: var(--f3);
  }
}
</style>
