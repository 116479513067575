<template>
  <section class="nav" :class="{ ocultarElemento: state.store?.fundo?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarDesktop />
    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <ElementoQuadro class="item" :store="state.store" :elemento="prepararEvento('fundoQuadro')" @quadro="quadro">
        <ElementoImg class="logo" :store="state.store" :elemento="prepararEvento('logo')" @imagem="imagem" />
        <ElementoDivSemFundo class="opcoes" :store="state.store" :elemento="prepararEvento('fundoTextoUm')" @secaoSemFundo="secaoSemFundo">
          <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao1')" @botao="botao" />
          <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao2')" @botao="botao" />
          <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao3')" @botao="botao" />
          <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao4')" @botao="botao" />
        </ElementoDivSemFundo>
        <ElementoDivSemFundo class="opcoes" :store="state.store" :elemento="prepararEvento('fundoTextoDois')" @secaoSemFundo="secaoSemFundo">
          <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao5')" @botao="botao" />
          <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao6')" @botao="botao" />
        </ElementoDivSemFundo>
      </ElementoQuadro>
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarDesktop from '@components/app/criar/partials/EditarDesktop.vue'

import ElementoButton from '@components/app/criar/elementos/button.vue'
import ElementoImg from '@components/app/criar/elementos/img.vue'
import ElementoQuadro from '@components/app/criar/elementos/quadro.vue'
import ElementoDivSemFundo from '@components/app/criar/elementos/divSemFundo.vue'
import ElementoSecao from '@components/app/criar/elementos/secao.vue'
import ElementoAvisoFixed from '@components/app/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const state = reactive({
  indiceSecao: null,
  store: {}
})

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function botao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesBotao', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function quadro(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesQuadro', { evento })
}

function secaoSemFundo(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'desktop'
  }
  return evento
}

function storeDaSecao() {
  const storeDesktop = storeAjustes.secoes.desktop
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeDesktop.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  background-size: cover;
  background-position: center;
  z-index: 2;
}

.opcoes {
  display: flex;
  align-items: center;
  position: relative;
  z-index: 2;
}
</style>
