<template>
  <div class="color" @keydown.esc="fecharColorPicker">
    <h3>Fundo</h3>
    <div class="item" :class="{ ativo: state.colorPicker.visible }" ref="balaoCores">
      <div class="info" @click="abrirColorPicker">
        <div class="bolinha" :style="{ backgroundColor: '#' + state.corLimpa }"></div>
        <p>#</p>
        <input type="text" v-model="state.corLimpa" @input="atualizarCor" />
      </div>
      <div class="balao">
        <div class="titulo">
          <p>Selecione uma cor</p>
          <button class="fechar" @click="fecharColorPicker">
            <Svgs nome="x" />
          </button>
        </div>
        <div class="itens">
          <ColorPicker v-if="state.colorPicker.visible" class="colorPicker" theme="dark" :color="state.colorPicker.color" :alpha="true" :sucker-hide="false" :sucker-canvas="state.colorPicker.suckerCanvas" :colors-default="colorsDefault" :sucker-area="state.colorPicker.suckerArea" @changeColor="escolherCor" />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, watch, reactive } from 'vue'
import { ColorPicker } from 'vue-color-kit'
import { useStoreAjustes } from '@stores'
import { helperClicouFora } from '../../../../helpers/index.js'
import Svgs from '@svgs'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()
const balaoCores = ref(null)

const state = reactive({
  colorPicker: {
    visible: false,
    color: '',
    suckerCanvas: null,
    suckerArea: [],
    isSucking: false
  },
  corLimpa: ''
})

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

const colorsDefault = ['#000000', '#404040', '#808080', '#C0C0C0', '#FFFFFe', '#FF0000', '#FF0000', '#FF3300', '#FF3300', '#FF6600', '#FF6600', '#FF9900', '#FF9900', '#FFCC00', '#FFCC00', '#FFFF00', '#FFFF00', '#CCFF00', '#CCFF00', '#99FF00', '#99FF00', '#66FF00', '#66FF00', '#33FF00', '#33FF00', '#00FF00', '#00FF00', '#00FF33', '#00FF33', '#00FF66', '#00FF66', '#00FF99', '#00FF99', '#00FFCC', '#00FFCC', '#00FFFF', '#00FFFF', '#00CCFF', '#00CCFF', '#0099FF', '#0099FF', '#0066FF', '#0066FF', '#0033FF', '#0033FF', '#0000FF', '#0000FF', '#3300FF', '#3300FF', '#6600FF', '#6600FF', '#9900FF', '#9900FF', '#CC00FF', '#CC00FF', '#FF00FF', '#FF00FF', '#FF00CC', '#FF00CC', '#FF0099', '#FF0099', '#FF0066', '#FF0066', '#FF0033', '#FF0033']

watch(
  () => elementoSelecionado.value.backgroundColor,
  (novaCor) => {
    state.corLimpa = novaCor
    state.colorPicker.color = novaCor
  },
  { immediate: true }
)

function escolherCor({ rgba }) {
  const hexValue = rgbaToHex(rgba.r, rgba.g, rgba.b, rgba.a)
  editarColor(hexValue)
}

function editarColor(valor) {
  state.colorPicker.color = '#' + valor
  state.corLimpa = valor
  elementoSelecionado.value.backgroundColor = valor
}

function atualizarCor(event) {
  state.corLimpa = event.target.value
  elementoSelecionado.value.backgroundColor = event.target.value
}

function abrirColorPicker() {
  state.colorPicker.visible = true
  state.colorPicker.color = '#' + state.corLimpa
}

function fecharColorPicker() {
  state.colorPicker.visible = false
}

function rgbaToHex(r, g, b, a) {
  const redHex = r.toString(16).padStart(2, '0')
  const greenHex = g.toString(16).padStart(2, '0')
  const blueHex = b.toString(16).padStart(2, '0')
  const alphaHex = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0')
  return redHex + greenHex + blueHex + alphaHex
}

helperClicouFora.clicouFora([{ alvo: balaoCores, retornoChamada: fecharColorPicker }])
</script>

<style scoped>
.color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.item .info {
  display: flex;
  align-items: center;
}

.item .info .bolinha {
  width: 30px;
  min-width: 30px;
  height: 30px;
  border-radius: 50%;
  border: 2px solid var(--cor-cinza-3);
  background-color: var(--cor-branco);
  cursor: pointer;
  transition: all 0.3s;
}

.item:hover .info .bolinha {
  border: 2px solid var(--cor-branco);
}

.item .info p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  pointer-events: none;
  margin: 0 5px 0 10px;
  transition: all 0.3s;
}

.item .info input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 5px 0 5px 0;
  text-transform: uppercase;
  width: 100%;
  background-color: var(--cor-cinza-2);
}

.item .info input:focus {
  color: var(--cor-branco);
}

.item .info input::placeholder {
  color: var(--cor-cinza-5);
}

.item .balao {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  background-color: var(--cor-cinza-2);
  bottom: 20px;
  border-radius: 10px;
  right: 290px;
  width: 100%;
  max-width: 300px;
  height: 50dvh;
  max-height: 50dvh;
  min-height: 50dvh;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 4;
  transition: all 0.5s;
}

.item.ativo .balao {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.item .balao .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px 10px 0 0;
  z-index: 2;
}

.item .balao .titulo p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.item .balao button.fechar {
  background-color: transparent;
}

.item .balao button.fechar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-vermelho);
  transition: all 0.3s;
}

.item .balao button.fechar:hover svg {
  fill: var(--cor-branco);
}

.item .balao .itens {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 50dvh;
  max-height: 50dvh;
  padding: 60px 20px 20px 20px;
  overflow-y: scroll;
  z-index: 1;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .item .balao {
    bottom: 20px;
    right: 20px;
    width: 100%;
    max-width: 250px;
    height: 42dvh;
    max-height: 42dvh;
    min-height: 42dvh;
  }
}
</style>
