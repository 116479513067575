<template>
  <section class="formulario" :class="{ ocultarElemento: state.store?.fundo?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarDesktop />
    <ElementoSecao class="modal" :store="state.store" :elemento="prepararEvento('modal')" @secao="secao">
      <ElementoDivSemFundo class="texto" :store="state.store" :elemento="prepararEvento('fundoTextoModal')" @secaoSemFundo="secaoSemFundo">
        <div class="tag">
          <ElementoImg :store="state.store" :elemento="prepararEvento('iconeTagModal')" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento('textoTagModal')" @texto="texto" />
        </div>
        <ElementoH2 :store="state.store" :elemento="prepararEvento('tituloModal')" @texto="texto" />
        <ElementoP :store="state.store" :elemento="prepararEvento('subtituloModal')" @texto="texto" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoModal')" @botao="botao" />
      </ElementoDivSemFundo>
    </ElementoSecao>

    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <ElementoDivSemFundo class="texto" :store="state.store" :elemento="prepararEvento('fundoTexto')" @secaoSemFundo="secaoSemFundo">
        <div class="tag">
          <ElementoImg :store="state.store" :elemento="prepararEvento('iconeTag')" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento('textoTag')" @texto="texto" />
        </div>
        <ElementoH2 :store="state.store" :elemento="prepararEvento('titulo')" @texto="texto" />
        <ElementoP :store="state.store" :elemento="prepararEvento('subtitulo')" @texto="texto" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botao')" @botao="botao" />
      </ElementoDivSemFundo>

      <ElementoQuadro class="form" :store="state.store" :elemento="prepararEvento('fundoForm')" @quadro="quadro">
        <div class="tag">
          <ElementoImg :store="state.store" :elemento="prepararEvento('iconeTagForm')" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento('textoTagForm')" @texto="texto" />
        </div>

        <ElementoH2 :store="state.store" :elemento="prepararEvento('tituloForm')" @texto="texto" />
        <ElementoP :store="state.store" :elemento="prepararEvento('subtituloForm')" @texto="texto" />

        <div class="inputs">
          <div
            class="campo"
            :style="`
            width: ${state.store?.inputUm?.maxWidth}px;
          `">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelUm')" @texto="texto" />
            <ElementoInput :store="state.store" :elemento="prepararEvento('inputUm')" @input="input" />
          </div>

          <div
            class="campo"
            :style="`
            width: ${state.store?.inputDois?.maxWidth}px;
          `">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelDois')" @texto="texto" />
            <ElementoInput :store="state.store" :elemento="prepararEvento('inputDois')" @input="input" />
          </div>

          <div
            class="campo"
            :style="`
  width: ${state.store?.inputTres?.maxWidth}px;
`">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelTres')" @texto="texto" />
            <ElementoInput :store="state.store" :elemento="prepararEvento('inputTres')" @input="input" />
          </div>

          <div
            class="campo"
            :style="`
  width: ${state.store?.inputQuatro?.maxWidth}px;
`">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelQuatro')" @texto="texto" />
            <ElementoInput :store="state.store" :elemento="prepararEvento('inputQuatro')" @input="input" />
          </div>

          <div
            class="campo"
            :style="`
  width: ${state.store?.textareaUm?.maxWidth}px;
`">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelCinco')" @texto="texto" />
            <ElementoTextarea :store="state.store" :elemento="prepararEvento('textareaUm')" @textarea="textarea" />
          </div>

          <div
            class="campo"
            :style="`
width: ${state.store?.inputCinco?.maxWidth}px;
`">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelSeis')" @texto="texto" />
            <ElementoInput :store="state.store" :elemento="prepararEvento('inputCinco')" @input="input" />
          </div>

          <div
            class="campo"
            :style="`
width: ${state.store?.inputSeis?.maxWidth}px;
`">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelSete')" @texto="texto" />
            <ElementoInput :store="state.store" :elemento="prepararEvento('inputSeis')" @input="input" />
          </div>

          <div
            class="campo"
            :style="`
            width: ${state.store?.selectUm?.maxWidth}px;
          `">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelOito')" @texto="texto" />
            <ElementoSelect :store="state.store" :elemento="prepararEvento('selectUm')" @select="select" />
          </div>

          <div
            class="campo"
            :style="`
  width: ${state.store?.selectDois?.maxWidth}px;
`">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelNove')" @texto="texto" />
            <ElementoSelect :store="state.store" :elemento="prepararEvento('selectDois')" @select="select" />
          </div>

          <div
            class="campo"
            :style="`
width: ${state.store?.selectTres?.maxWidth}px;
`">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelDez')" @texto="texto" />
            <ElementoSelect :store="state.store" :elemento="prepararEvento('selectTres')" @select="select" />
          </div>

          <div
            class="campo"
            :style="`
  width: ${state.store?.textareaDois?.maxWidth}px;
`">
            <ElementoLabel :store="state.store" :elemento="prepararEvento('labelOnze')" @texto="texto" />
            <ElementoTextarea :store="state.store" :elemento="prepararEvento('textareaDois')" @textarea="textarea" />
          </div>
        </div>

        <ElementoButtonDiscord :store="state.store" :elemento="prepararEvento('botaoForm')" @webhookDiscord="webhookDiscord" />
      </ElementoQuadro>

      <ElementoDivisor :store="state.store" :elemento="prepararEvento('divisor')" @divisor="divisor" class="divisor" />
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarDesktop from '@components/app/criar/partials/EditarDesktop.vue'

import ElementoH2 from '@components/app/criar/elementos/h2.vue'
import ElementoP from '@components/app/criar/elementos/p.vue'
import ElementoButton from '@components/app/criar/elementos/button.vue'
import ElementoImg from '@components/app/criar/elementos/img.vue'
import ElementoDivisor from '@components/app/criar/elementos/divisor.vue'
import ElementoQuadro from '@components/app/criar/elementos/quadro.vue'
import ElementoSecao from '@components/app/criar/elementos/secao.vue'
import ElementoDivSemFundo from '@components/app/criar/elementos/divSemFundo.vue'
import ElementoLabel from '@components/app/criar/elementos/label.vue'
import ElementoInput from '@components/app/criar/elementos/input.vue'
import ElementoTextarea from '@components/app/criar/elementos/textarea.vue'
import ElementoSelect from '@components/app/criar/elementos/select.vue'
import ElementoButtonDiscord from '@components/app/criar/elementos/buttonDiscord.vue'
import ElementoAvisoFixed from '@components/app/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const state = reactive({
  indiceSecao: null,
  store: {}
})

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function divisor(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesDivisor', { evento })
}

function botao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesBotao', { evento })
}

function webhookDiscord(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesWebhookDiscord', { evento })
}

function texto(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesTexto', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function quadro(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesQuadro', { evento })
}

function input(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesInput', { evento })
}

function textarea(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesTextarea', { evento })
}

function select(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSelect', { evento })
}

function secaoSemFundo(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'desktop'
  }
  return evento
}

function storeDaSecao() {
  const storeDesktop = storeAjustes.secoes.desktop
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeDesktop.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.modal {
  display: flex;
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: -1600px;
  width: 100%;
  min-height: 100%;
}

.modal .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 2;
}

.modal .texto .tag {
  display: flex;
  align-items: center;
  z-index: 3;
}

.texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  position: relative;
}

.texto .tag {
  display: flex;
  align-items: center;
  z-index: 3;
}

.form {
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 1;
  width: 100%;
}

.form .tag {
  display: flex;
  align-items: center;
  z-index: 3;
}

.form .inputs {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
}

.form .inputs .campo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
</style>
