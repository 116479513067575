<template>
  <Modal nome="googleTag">
    <label>
      Script do Google Tag Manager &lt;head&gt;
      <span>*</span>
      <a href="" style="display: none">
        <Svgs nome="duvida" />
        <p>Como configurar Google Tag</p>
      </a>
    </label>
    <textarea placeholder="Cole aqui o código script..." v-model="state.scriptCompleto" spellcheck="false" autocomplete="false" v-redimensionar-textarea></textarea>

    <BotaoSalvar nome="Salvar Google Tag" @click="salvarTagManager" />
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { useStorePages, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const storePages = useStorePages()
const storeModal = useStoreModal()

const state = reactive({
  id: '',
  scriptCompleto: ''
})

function salvarTagManager() {
  extrairGTM()

  const payload = {
    idPage: storePages.page._id,
    integracoes: {
      tagManager: {
        id: state.id
      }
    }
  }

  storePages.alterarDados(payload)
  storeModal.fecharModal('googleTag')
}

function gerarCodigo(id) {
  state.scriptCompleto = `
  <!-- Google Tag Manager -->
&lt;script&gt;(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
j=d.createElement(s),dl=l!='dataLayer'?'&amp;l='+l:'';j.async=true;j.src=
'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
})(window,document,'script','dataLayer','${id}');&lt;/script&gt;
<!-- End Google Tag Manager -->
  `
}

function extrairGTM() {
  const regex = /(GTM-[A-Z0-9]+|AW-[0-9]+)/i
  const match = state.scriptCompleto.match(regex)

  if (match) {
    state.id = match[0]
  } else {
    state.id = ''
  }
}

function carregarDados() {
  state.id = storePages.page?.integracoes?.tagManager?.id || ''
  if (state.id) {
    gerarCodigo(state.id)
  }
}

function limparCampos() {
  state.id = ''
  state.scriptCompleto = ''
}

onMounted(() => {
  emitter.on('carregarCampos', carregarDados)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
label {
  margin: 0 0 10px 0;
  position: relative;
  width: 100%;
}

button {
  margin: 20px 0 0 0;
}

a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s;
}

a:hover {
  opacity: 0.6;
}

a svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
