<template>
  <Modal nome="geradorLinkWhatsapp">
    <div class="quadro" v-if="!state.linkGerado">
      <p>Preencha no campo abaixo o número de whatsapp e a mensagem que deseja no link para gerar a URL personalizada.</p>

      <label>Número whatsapp:</label>
      <div class="input">
        <Svgs nome="whatsapp" class="icone" />
        <input type="tel" spellcheck="false" v-model="state.whatsapp" v-mask="mascaraAtual" placeholder="(00) 00000-0000" />
      </div>

      <label>Mensagem que deseja no link:</label>
      <div class="textarea">
        <textarea v-model="state.mensagem" placeholder="Digite aqui..." spellcheck="false" v-redimensionar-textarea></textarea>
      </div>

      <div class="botao">
        <BotaoSalvar @click="gerarLink" nome="Gerar Link Whatsapp" />
      </div>
    </div>

    <div class="link" v-if="state.linkGerado">
      <p>Seu link gerado (clique para copiar)</p>
      <div class="campo">
        <textarea readonly v-model="state.linkGerado" @click="copiarLink" spellcheck="false" v-redimensionar-textarea></textarea>
        <Svgs nome="duplicar" />
      </div>
      <BotaoSalvar @click="resetarFormulario" nome="Gerar outro link" />
    </div>
  </Modal>
</template>

<script setup>
import { computed, reactive, inject, onMounted } from 'vue'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')

const state = reactive({
  whatsapp: '',
  mensagem: '',
  linkGerado: ''
})

const mascaraAtual = computed(() => {
  const digitos = state.whatsapp.replace(/\D/g, '')
  return digitos.length <= 10 ? '(##) ####-####' : '(##) #####-####'
})

function gerarLink() {
  if (!state.whatsapp) {
    const mensagem = 'Preencha o número do whatsapp.'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return
  }

  const numeroFormatado = state.whatsapp.replace(/\D/g, '')
  const mensagemFormatada = encodeURIComponent(state.mensagem)

  if (!state.mensagem) {
    state.linkGerado = `https://api.whatsapp.com/send?phone=${numeroFormatado}`
  } else {
    state.linkGerado = `https://api.whatsapp.com/send?phone=${numeroFormatado}&text=${mensagemFormatada}`
  }
}

function copiarLink() {
  navigator.clipboard.writeText(state.linkGerado).then(() => {
    const mensagem = 'Link copiado com sucesso!'
    emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem })
  })
}

function resetarFormulario() {
  limparCampos()
}

function limparCampos() {
  state.mensagem = ''
  state.linkGerado = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: fadeIn 0.3s linear;
}

.quadro h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.quadro label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 25px 0 10px 0;
}

.quadro .botao {
  width: 100%;
  margin: 20px 0 0 0;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  animation: fadeIn 0.3s linear;
}

.link p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.link .campo {
  position: relative;
  width: 100%;
}

.link .campo svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-4);
  position: absolute;
  top: 10px;
  right: 10px;
  pointer-events: none;
}

.link textarea {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--cor-cinza-1);
  border: 1px dashed var(--cor-cinza-4);
  width: 100%;
  resize: none;
  transition: all 0.3s;
}

.link textarea:hover {
  border: 1px dashed var(--cor-cinza-5);
}
</style>
