<template>
  <div class="text">
    <h3>Monte sua seção com código. Use HTML e CSS. JavaScript não é permitido nesse campo.</h3>
    <MonacoEditor class="editor um" v-model="state.html" language="html" />
    <button @click="state.zoom = !state.zoom">
      <Svgs nome="zoom-in" />
      <p>Aumentar</p>
    </button>
    <div class="edicao" :class="{ mostrar: state.zoom }">
      <div class="nome">
        <div class="texto">
          <h3>Monte sua seção com código</h3>
          <p>Use HTML e CSS. JavaScript não é permitido nesse campo.</p>
        </div>
        <button @click="state.zoom = !state.zoom">
          <Svgs nome="zoom-out" />
          <p>Diminuir</p>
        </button>
      </div>
      <MonacoEditor class="editor dois" v-model="state.html" language="html" />
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect, watch } from 'vue'
import { useStoreAjustes } from '@stores'
import MonacoEditor from '@components/global/editor/Editor.vue'
import Svgs from '@svgs'

const storeAjustes = useStoreAjustes()

const props = defineProps(['selecao'])
const state = reactive({
  html: '',
  zoom: false
})

function editarText() {
  elementoSelecionado().codigo = state.html
}

function carregarText() {
  state.html = elementoSelecionado().codigo
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watch(
  () => state.html,
  () => {
    editarText()
  }
)

watchEffect(() => {
  carregarText()
})
</script>

<style scoped>
.edicao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: var(--cor-cinza-2);
  padding: 30px;
  border-radius: 10px;
  position: fixed;
  top: 20px;
  right: 20px;
  width: 600px;
  height: calc(100dvh - 30px);
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: scale(0.9);
  transition: all 0.3s;
}

.edicao.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: scale(1);
}

.edicao button {
  margin: 0;
}

.edicao .nome {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.edicao .nome .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.edicao .nome .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
  line-height: 1.5;
}

.edicao .nome .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  line-height: 1.5;
}

.text {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
  line-height: 1.5;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 20px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

button:hover {
  background-color: var(--cor-cinza-4);
}

button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

button p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.editor.um {
  width: 100%;
  height: 350px;
  min-height: 350px;
  border-radius: 10px;
}

.editor.dois {
  width: 100%;
  height: calc(100dvh - 80px);
  border-radius: 10px;
}

@media screen and (max-width: 1024px) {
  .chat {
    right: 20px;
    width: calc(100% - 40px);
    max-width: calc(100% - 40px);
    height: 55dvh;
    max-height: 55dvh;
    min-height: 55dvh;
  }

  .conteudo {
    height: 55dvh;
    max-height: 55dvh;
  }
}
</style>
