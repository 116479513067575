<template>
  <div id="alerta" :class="{ [classe]: true, mostrar: visivel }" @click="storeAlerta.fecharAlerta()">
    <Svgs :nome="classe === 'positivo' ? 'check' : 'x'" />
    <p>{{ mensagem }}</p>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { useStoreAlerta } from '@stores'
import Svgs from '@svgs'

const storeAlerta = useStoreAlerta()
const { classe, visivel, mensagem } = storeToRefs(storeAlerta)
</script>

<style scoped>
div#alerta {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 20px;
  left: 50%;
  z-index: 10000;
  width: 100%;
  max-width: 400px;
  background-color: var(--cor-cinza-1);
  padding: 15px 20px;
  border-radius: 10px;
  transform: scale(0.9) translate(-50%, 0);
}

div#alerta.mostrar {
  display: flex;
  transform: scale(1) translate(-50%, 0);
  animation: mostrarAlerta 0.3s linear;
}

@keyframes mostrarAlerta {
  0% {
    opacity: 0;
    transform: scale(0.9) translate(-50%, 0);
  }
  100% {
    opacity: 1;
    transform: scale(1) translate(-50%, 0);
  }
}

svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-branco-fixo);
  margin: 0 10px 0 0;
}

p {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  line-height: 1.5;
  width: 100%;
}

div#alerta.positivo {
  background-color: #208d56;
  box-shadow: 10px 20px 30px 0 #208d561f;
}

div#alerta.negativo {
  background-color: #ff3939;
  box-shadow: 10px 20px 30px 0 #ff39391f;
}

@media screen and (max-width: 1024px) {
  div#alerta {
    max-width: calc(100% - 40px);
  }
}
</style>
