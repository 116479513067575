<template>
  <section class="videos">
    <div class="conteudo" v-for="tutorial in state.tutoriais" :key="tutorial">
      <div class="video">
        <div class="tamanho">
          <iframe :src="tutorial.urlVideo" :title="tutorial.titulo" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </div>
      </div>
      <div class="texto">
        <h2>{{ tutorial.titulo }}</h2>
        <p>{{ tutorial.descricao }}</p>
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'

const state = reactive({
  tutoriais: [
    {
      titulo: 'Tutorial rápido de 2 minutos',
      descricao: 'Tenha uma prévia completa do que é e como funciona a UnicPages em apenas 2 minutos',
      urlVideo: 'https://www.youtube.com/embed/KwJmC8s972s?si=H3vUTR9h-Q4nZRr3'
    },
    {
      titulo: 'Aulão de 2 horas na Unic',
      descricao: 'Um aulão completo criando do absoluto zero uma Landing Page na UnicPages',
      urlVideo: 'https://www.youtube.com/embed/s8lNDMs1d4Q?si=vnYBYm4pK1B3qfus'
    },
    {
      titulo: 'Curso Rápido Unic',
      descricao: 'Um curso te ensinando a construir páginas de vendas usando a UnicPages',
      urlVideo: 'https://www.youtube.com/embed/videoseries?si=BIlVKSX4gmiFDT4c&amp;list=PLGpXTm_pQbxkJPMj3K923oSObMniMm8aL'
    },
    {
      titulo: 'Integração com Active Campaign',
      descricao: 'Aprenda em um vídeo completo a integrar Unic com Active Campaign',
      urlVideo: 'https://www.youtube.com/embed/DbCpav4b0yI?si=MLyYXQkuXOQlbVir'
    }
  ]
})
</script>

<style scoped>
section.videos {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 20px 0 320px;
}

.conteudo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  width: 100%;
  margin: 0 0 20px 0;
}

.texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 40px;
}

p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

h2 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco);
  line-height: 1.3;
}

.video {
  width: 100%;
  max-width: 400px;
}

.video .tamanho {
  position: relative;
  width: 100%;
  padding-bottom: 56.52%;
}

.video .tamanho iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--cor-preto);
  border-radius: 10px;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.videos {
    padding: 20px 20px 0 20px;
  }

  .conteudo {
    flex-direction: column;
    align-items: flex-start;
  }

  .texto {
    padding: 30px;
  }

  h2 {
    font-size: var(--f3);
  }
}
</style>
