<template>
  <Modal nome="geradorQrCode">
    <div class="quadro" v-if="!state.qrCodeGerado">
      <p>Preencha no campo abaixo o link que deseja gerar no seu QRcode. Esse é o link para onde será levado quem ler o QRcode.</p>

      <label>Link:</label>
      <div class="input">
        <Svgs nome="link" class="icone" />
        <input type="url" spellcheck="false" v-model="state.link" placeholder="Digite o link aqui" />
      </div>

      <div class="botao">
        <BotaoSalvar @click="gerarQRCode" nome="Gerar QR Code" />
      </div>
    </div>

    <div class="link" v-if="state.qrCodeGerado">
      <div class="opcoes">
        <div class="opcao" ref="balaoCores">
          <label>Cor do Fundo:</label>
          <span @click="state.colorPicker.visivel = !state.colorPicker.visivel" :style="`background-color: ${state.corFundo}`"></span>
          <ColorPicker v-if="state.colorPicker.visivel" class="colorPicker simples" theme="dark" :color="state.colorPicker.color" :alpha="true" :sucker-hide="false" :sucker-canvas="state.colorPicker.suckerCanvas" :sucker-area="state.colorPicker.suckerArea" @changeColor="updateCorFundo" />
        </div>
        <div class="opcao" ref="balaoCoresDois">
          <label>Cor do QR Code:</label>
          <span @click="state.colorPickerDois.visivel = !state.colorPickerDois.visivel" :style="`background-color: ${state.corQrCode}`"></span>
          <ColorPicker v-if="state.colorPickerDois.visivel" class="colorPicker simples" theme="dark" :color="state.colorPickerDois.color" :alpha="true" :sucker-hide="false" :sucker-canvas="state.colorPickerDois.suckerCanvas" :sucker-area="state.colorPickerDois.suckerArea" @changeColor="updateCorQrCode" />
        </div>
      </div>

      <div class="qrcode" :style="`background-color: ${state.corFundo}`">
        <canvas ref="qrCanvas"></canvas>
        <canvas class="original" ref="qrCanvasOriginal"></canvas>
      </div>

      <BotaoSalvar @click="baixarQRCode" nome="Baixar QR Code" />
      <BotaoSalvar @click="resetarFormulario" nome="Gerar outro QR Code" />
    </div>
  </Modal>
</template>

<script setup>
import { reactive, ref, inject, watch, nextTick } from 'vue'
import { useStoreFerramentas } from '@stores'
import { helperClicouFora } from '@helpers'
import { ColorPicker } from 'vue-color-kit'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import Svgs from '@svgs'
import QRCode from 'qrcode'

const storeFerramentas = useStoreFerramentas()
const emitter = inject('emitter')
const qrCanvas = ref(null)
const qrCanvasOriginal = ref(null)
const balaoCores = ref(null)
const balaoCoresDois = ref(null)

const state = reactive({
  link: '',
  qrCodeGerado: false,
  corFundo: '#ffffff',
  corQrCode: '#000000',
  colorPicker: {
    visivel: false,
    color: '',
    suckerCanvas: null,
    suckerArea: [],
    isSucking: false
  },
  colorPickerDois: {
    visivel: false,
    color: '',
    suckerCanvas: null,
    suckerArea: [],
    isSucking: false
  }
})

function fecharModal() {
  storeFerramentas.mostrarModalQrCode = false
  resetarFormulario()
}

async function gerarQRCode() {
  if (!state.link) {
    const mensagem = 'Por favor, preencha o campo de link.'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return
  }

  state.qrCodeGerado = true
  await nextTick()
  desenharQRCode()
  desenharQRCodeOriginal()
}

function desenharQRCode() {
  QRCode.toCanvas(
    qrCanvas.value,
    state.link,
    {
      errorCorrectionLevel: 'H',
      color: {
        dark: state.corQrCode,
        light: state.corFundo
      }
    },
    (error) => {
      if (error) {
        console.log(error)
      }
    }
  )
}

function desenharQRCodeOriginal() {
  const canvas = qrCanvasOriginal.value
  const size = 1080

  canvas.width = size
  canvas.height = size

  QRCode.toCanvas(
    canvas,
    state.link,
    {
      errorCorrectionLevel: 'H',
      width: size,
      color: {
        dark: state.corQrCode,
        light: state.corFundo
      }
    },
    (error) => {
      console.log(error)
    }
  )
}

watch([() => state.corFundo, () => state.corQrCode], desenharQRCode, { deep: true })

function baixarQRCode() {
  const canvas = qrCanvasOriginal.value
  const link = document.createElement('a')

  const tempCanvas = document.createElement('canvas')
  tempCanvas.width = 1080
  tempCanvas.height = 1080
  const ctx = tempCanvas.getContext('2d')

  ctx.drawImage(canvas, 0, 0, 1080, 1080)

  link.href = tempCanvas.toDataURL('image/png')
  link.download = 'qrcode.png'
  link.click()
}

function updateCorFundo({ hex }) {
  state.corFundo = hex
  desenharQRCode()
  desenharQRCodeOriginal()
}

function updateCorQrCode({ hex }) {
  state.corQrCode = hex
  desenharQRCode()
  desenharQRCodeOriginal()
}

function resetarFormulario() {
  state.link = ''
  state.qrCodeGerado = false
  state.corFundo = '#ffffff'
  state.corQrCode = '#000000'
  const canvas = qrCanvas.value
  if (canvas) {
    const ctx = canvas.getContext('2d')
    ctx.clearRect(0, 0, canvas.width, canvas.height)
  }
}

function fecharColorPicker() {
  state.colorPicker.visivel = false
}

function fecharColorPickerDois() {
  state.colorPickerDois.visivel = false
}

helperClicouFora.clicouFora([
  { alvo: balaoCores, retornoChamada: fecharColorPicker },
  { alvo: balaoCoresDois, retornoChamada: fecharColorPickerDois }
])
</script>

<style scoped>
.quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  animation: fadeIn 0.3s linear;
}

.quadro h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.quadro label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 25px 0 10px 0;
}

.quadro .botao {
  width: 100%;
  margin: 20px 0 0 0;
}

.colorPicker.simples {
  position: absolute;
  top: 70px;
  left: -20px;
  padding: 30px 0 30px 30px;
  animation: fadeIn 0.3s linear;
}

.link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  animation: fadeIn 0.3s linear;
}

.link p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.link .opcoes {
  display: flex;
  width: 100%;
  justify-content: space-between;
  padding: 0 0 20px 0;
}

.link .opcoes .opcao {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: calc(50% - 5px);
  border-radius: 10px;
  position: relative;
}

.link .opcoes .opcao label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.link .opcoes .opcao span {
  width: 100%;
  height: 40px;
  min-height: 40px;
  border-radius: 50px;
  border: 1px solid var(--cor-cinza-4);
  transition: all 0.3s;
}

.link .opcoes .opcao span:hover {
  transform: scale(1.05);
}

.link .qrcode {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-radius: 10px;
}

.link canvas {
  width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
  transition: all 0.3s;
}

.link canvas.original {
  position: fixed;
  top: -2080px;
  width: 1080px;
  height: 1080px;
  min-width: 1080px;
  min-height: 1080px;
}
.link button {
  margin: 10px 0 0 0;
}
</style>
