<template>
  <div class="loading" :class="{ ocultar: mostrarLoading }">
    <span class="um"></span>
    <p>{{ texto }}</p>
  </div>
</template>

<script setup>
import { storeToRefs } from 'pinia'
import { ref, onMounted } from 'vue'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()
const { mostrarLoading } = storeToRefs(storeCriar)

const palavras = ['Carregando', 'UnicPages']
const indice = ref(0)
const texto = ref('')

onMounted(() => {
  digitar()
})

const digitar = () => {
  const palavraAtual = palavras[indice.value]

  texto.value = ''

  let i = 0
  const intervalo = setInterval(() => {
    texto.value += palavraAtual[i]
    i++

    if (i === palavraAtual.length) {
      clearInterval(intervalo)
      indice.value = (indice.value + 1) % palavras.length
      setTimeout(digitar, 1000)
    }
  }, 100)
}
</script>

<style scoped>
.loading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--cor-cinza-1);
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  z-index: 9;
}
.loading span.um {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  border-top: 2px solid transparent;
  border-left: 2px solid var(--cor-branco);
  animation: rodandoSpanLoading 1s ease-in-out infinite;
  z-index: 2;
}
@keyframes rodandoSpanLoading {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading.ocultar {
  animation: ocultarLoading 0.3s ease-in-out;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}
@keyframes ocultarLoading {
  0% {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
  100% {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }
}
p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 20px 0 0 0;
  min-height: 20px;
  z-index: 2;
}
.loading span.dois {
  position: absolute;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background-color: var(--cor-cinza-2);
  animation: rodandoSpanDoisLoading 5s linear infinite;
  z-index: 1;
}
@keyframes rodandoSpanDoisLoading {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  100% {
    transform: scale(4);
    opacity: 0;
  }
}
</style>
