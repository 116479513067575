function bloquearBodyQuandoAbrirModal() {
 const conteudoHtml = document.body.innerHTML
 const bodyBloqueado = document.body?.classList?.contains('bloquear')
 const modalAberta = conteudoHtml?.includes('modal mostrar')

 if(modalAberta && !bodyBloqueado) document.body?.classList?.add('bloquear')
 else if(!modalAberta && bodyBloqueado) document.body?.classList?.remove('bloquear')
 else return
}

export default { bloquearBodyQuandoAbrirModal }
