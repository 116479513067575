import { defineStore } from 'pinia'

export const useStoreSites = defineStore('storeSites', {
  state: () => ({
    mostrarModalCriarZero: false,
    mostrarModalExcluirSite: false,
    mostrarModalDuplicar: false,
    mostrarModalOnboarding: false,
    mostrarModalExpirou: false,
    mostrarModalActiveCampaign: false,
    mostrarModalGoogleMaps: false,
    mostrarModalDiscord: false,
    idPage: ''
  })
})
