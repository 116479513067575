import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiRemoteStorage from '@/api/unicpages/api-remote-storage.js'

export const useStoreRemoteStorage = defineStore('storeRemoteStorage', {
  state: () => ({}),

  actions: {
    async receberOnboarding() {
      try {
        const resp = await apiRemoteStorage.receberOnboarding()
        return resp
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarOnboarding(payload) {
      try {
        await apiRemoteStorage.alterarOnboarding(payload)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
