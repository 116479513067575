<template>
  <section class="html" :class="{ ocultarElemento: state.store?.html?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.html?.positionFixed" />
    <EditarMobile />
    <ElementoHtml class="conteudo" :store="state.store" :elemento="prepararEvento('html')" @html="html">
      <div class="body" ref="shadowRootContainer"></div>
    </ElementoHtml>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive, ref, watch } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarMobile from '@components/app/criar/partials/EditarMobile.vue'
import ElementoHtml from '@components/app/criar/elementos/html.vue'
import ElementoAvisoFixed from '@components/app/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()
const emitter = inject('emitter')

const state = reactive({
  indiceSecao: null,
  store: {}
})

const shadowRootContainer = ref(null)
let shadowRoot = null

function html(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesHtml', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'mobile'
  }
  return evento
}

function storeDaSecao() {
  const storeMobile = storeAjustes.secoes.mobile
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeMobile.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

function renderShadowDom(htmlContent) {
  if (!shadowRoot && shadowRootContainer.value) {
    shadowRoot = shadowRootContainer.value.attachShadow({ mode: 'open' })
  }
  if (shadowRoot) {
    shadowRoot.innerHTML = htmlContent
  }
}

watch(
  () => state.store?.html?.codigo,
  (newHtml) => {
    const htmlString = newHtml ? newHtml.toString() : ''
    renderShadowDom(htmlString)
  },
  { immediate: true, deep: true }
)

onMounted(() => {
  storeDaSecao()
  const initialHtmlString = state.store?.html?.codigo ? state.store?.html?.codigo.toString() : ''
  renderShadowDom(initialHtmlString)
})
</script>

<style scoped>
.conteudo {
  width: 100%;
}
.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
}
</style>
