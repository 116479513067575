<template>
  <div id="ferramentas">
    <ModalLinkWhatsapp />
    <ModalGerarQrCode />
    <SectionTopo />
    <SectionLista />
    <Footer />
  </div>
</template>

<script setup>
import { onMounted, inject } from 'vue'
import { useStorePerfil, useStorePages } from '@stores'
import SectionTopo from '@components/app/ferramentas/partials/SectionTopo.vue'
import ModalLinkWhatsapp from '@components/app/ferramentas/modals/ModalLinkWhatsapp.vue'
import ModalGerarQrCode from '@components/app/ferramentas/modals/ModalGerarQrCode.vue'
import SectionLista from '@components/app/ferramentas/partials/SectionLista.vue'
import Footer from '@components/global/footer/Footer.vue'

const storePerfil = useStorePerfil()
const storePages = useStorePages()
const emitter = inject('emitter')

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

function carregarPages() {
  storePages.receberTodos()
}

onMounted(() => {
  carregarPerfil()
  carregarPages()
  emitter.on('atualizarPages', carregarPages)
})
</script>

<style scoped></style>
