import { defineStore } from 'pinia'

export const useStoreHtml1Mobile = defineStore('storeHtml1Mobile', {
  state: () => ({
    html: {
      hidden: false,
      positionFixed: false,
      codigo: `
<section class="apresentacao">
  <h1>Seção em código</h1>
  <p>Crie com HTML & CSS</p>
</section>
<style>
  section {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: #000;
  }
  section h1 {
    font-size: 50px;
    line-height: 1;
    color: #fff;
  }
  section p {
    font-size: 30px;
    color: #fff;
  }
</style>`
    }
  })
})
