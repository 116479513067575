import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiGpt from '@/api/gpt/api-gpt.js'

export const useStoreGpt = defineStore('storeGpt', {
  state: () => {},
  actions: {
    async chat(payload) {
      try {
        return apiGpt.chat(payload)
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
