<template>
  <div class="link">
    <h3>Link</h3>
    <textarea rows="5" spellCheck="false" placeholder="Digite aqui" @input="editarLink($event.target.value)" v-model="state.link"></textarea>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  link: ''
})

function editarLink(valor) {
  elementoSelecionado().link = valor
}

function carregarLink() {
  state.link = elementoSelecionado().link
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  carregarLink()
})
</script>

<style scoped>
.link {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

textarea {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 15px;
  border-radius: 10px;
  resize: none;
  line-height: 1.5;
  width: 100%;
  transition: all 0.3s;
}

textarea:focus {
  background-color: var(--cor-cinza-4);
}

textarea::placeholder {
  color: var(--cor-cinza-5);
}
</style>
