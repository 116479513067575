<template></template>

<script setup>
import pako from 'pako'
import { reactive, onMounted } from 'vue'
import { useStoreAjustes, useStorePages } from '@stores'

const storeAjustes = useStoreAjustes()
const storePages = useStorePages()

const state = reactive({
  store: null
})

const receberStore = () => (state.store = JSON.parse(JSON.stringify(storeAjustes.secoes)))

const verificarAtualizacoesNaStore = () => {
  const dadosDaStore = JSON.stringify(storeAjustes.secoes)
  if (dadosDaStore !== JSON.stringify(state.store)) {
    receberStore()
    atualizarBackend()
  }
}

const atualizarBackend = () => {
  const payload = {
    idPage: storePages.page._id,
    rascunhoComprimidoDesktop: compressPayload(state.store.desktop),
    rascunhoComprimidoMobile: compressPayload(state.store.mobile)
  }
  storePages.alterarRascunho(payload, true)
}

const compressPayload = (content) => {
  const compressedString = String.fromCharCode(...pako.deflate(new TextEncoder().encode(JSON.stringify(content))))
  return btoa(compressedString)
}

onMounted(() => {
  receberStore()
  setInterval(verificarAtualizacoesNaStore, 2500)
})
</script>
