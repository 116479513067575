<template>
  <section class="plano" :class="{ ocultarElemento: state.store?.fundo?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarMobile />
    <ElementoSecao class="conteudo" :class="{ ativo: state.ativarToggle }" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <ElementoItem class="toggle" :store="state.store" :elemento="prepararEvento('fundoToggle')" @item="item">
        <div class="parte um">
          <ElementoImg :store="state.store" :elemento="prepararEvento('iconeToggleUm')" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento('textoToggleUm')" @texto="texto" />
        </div>

        <div class="parte dois">
          <ElementoImg :store="state.store" :elemento="prepararEvento('iconeToggleDois')" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento('textoToggleDois')" @texto="texto" />
        </div>

        <ElementoItem class="span" :store="state.store" :elemento="prepararEvento('spanToggle')" @item="item" @click="state.ativarToggle = !state.ativarToggle"></ElementoItem>
      </ElementoItem>

      <!-- itens -->
      <ElementoDivSemFundo class="itens" :store="state.store" :elemento="prepararEvento('fundoItens')" @secaoSemFundo="secaoSemFundo">
        <ElementoQuadro v-for="item in items" :key="item" class="item" :store="state.store" :elemento="prepararEvento(`fundoQuadro${item}`)" @quadro="quadro">
          <ElementoImg class="icone" :store="state.store" :elemento="prepararEvento(`nomeIconeQuadro${item}`)" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento(`nomeTituloQuadro${item}`)" @texto="texto" />
          <ElementoP :store="state.store" :elemento="prepararEvento(`nomeSubtituloQuadro${item}`)" @texto="texto" />

          <div class="preco">
            <div class="mensal">
              <div class="valor">
                <div class="parte um">
                  <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoUmQuadro${item}`)" @texto="texto" />
                  <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoDoisQuadro${item}`)" @texto="texto" />
                </div>
                <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoTresQuadro${item}`)" @texto="texto" />
                <div class="parte">
                  <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoQuatroQuadro${item}`)" @texto="texto" />
                  <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoCincoQuadro${item}`)" @texto="texto" />
                </div>
              </div>
              <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoSeisQuadro${item}`)" @texto="texto" />
            </div>
            <div class="anual">
              <div class="valor">
                <div class="parte um">
                  <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoSeteQuadro${item}`)" @texto="texto" />
                  <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoOitoQuadro${item}`)" @texto="texto" />
                </div>
                <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoNoveQuadro${item}`)" @texto="texto" />
                <div class="parte">
                  <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoDezQuadro${item}`)" @texto="texto" />
                  <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoOnzeQuadro${item}`)" @texto="texto" />
                </div>
              </div>
              <ElementoH2 :store="state.store" :elemento="prepararEvento(`precoDozeQuadro${item}`)" @texto="texto" />
            </div>
          </div>

          <div class="botoes">
            <ElementoButton class="um" :store="state.store" :elemento="prepararEvento(`botaoUmQuadro${item}`)" @botao="botao" />
            <ElementoButton class="dois" :store="state.store" :elemento="prepararEvento(`botaoDoisQuadro${item}`)" @botao="botao" />
          </div>

          <ElementoH2 :store="state.store" :elemento="prepararEvento(`listaTituloQuadro${item}`)" @texto="texto" />

          <ElementoQuadro v-for="parte in partes" :key="parte" class="parte" :store="state.store" :elemento="prepararEvento(`listaParte${parte}Quadro${item}`)" @quadro="quadro">
            <ElementoImg :store="state.store" :elemento="prepararEvento(`iconeParte${parte}Quadro${item}`)" @imagem="imagem" />
            <ElementoH2 :store="state.store" :elemento="prepararEvento(`textoUmParte${parte}Quadro${item}`)" @texto="texto" />
            <ElementoH2 :store="state.store" :elemento="prepararEvento(`textoDoisParte${parte}Quadro${item}`)" @texto="texto" />
          </ElementoQuadro>
        </ElementoQuadro>
      </ElementoDivSemFundo>

      <ElementoDivisor :store="state.store" :elemento="prepararEvento('divisor')" @divisor="divisor" class="divisor" />
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarMobile from '@components/app/criar/partials/EditarMobile.vue'

import ElementoH2 from '@components/app/criar/elementos/h2.vue'
import ElementoP from '@components/app/criar/elementos/p.vue'
import ElementoButton from '@components/app/criar/elementos/button.vue'
import ElementoImg from '@components/app/criar/elementos/img.vue'
import ElementoDivisor from '@components/app/criar/elementos/divisor.vue'
import ElementoQuadro from '@components/app/criar/elementos/quadro.vue'
import ElementoSecao from '@components/app/criar/elementos/secao.vue'
import ElementoItem from '@components/app/criar/elementos/item.vue'
import ElementoDivSemFundo from '@components/app/criar/elementos/divSemFundo.vue'
import ElementoAvisoFixed from '@components/app/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const items = ['Um', 'Dois', 'Tres', 'Quatro']

const partes = ['Um', 'Dois', 'Tres', 'Quatro']

const state = reactive({
  indiceSecao: null,
  store: {},
  ativarToggle: false
})

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function divisor(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesDivisor', { evento })
}

function texto(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesTexto', { evento })
}

function botao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesBotao', { evento })
}

function item(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesItem', { evento })
}

function quadro(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesQuadro', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function secaoSemFundo(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'mobile'
  }
  return evento
}

function storeDaSecao() {
  const storeMobile = storeAjustes.secoes.mobile
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeMobile.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 2;
}

.toggle .parte {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 50%;
  z-index: 4;
}

.toggle .parte.um {
  left: 0;
}

.toggle .parte.dois {
  right: 0;
}

.conteudo.ativo .toggle .span {
  transform: translate(100%, 0);
}

.toggle .span {
  z-index: 3;
  transform: translate(0%, 0);
  transition: all 0.3s !important;
}

.itens {
  display: flex;
  position: relative;
  width: 100%;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  width: 100%;
  z-index: 2;
}

.preco {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  overflow: hidden;
  min-height: 150px;
  z-index: 3;
}

.conteudo.ativo .preco .mensal {
  left: -100%;
}

.conteudo.ativo .preco .anual {
  left: 0;
}

.preco .valor {
  display: flex;
  align-items: center;
  width: 100%;
}

.preco .parte {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

.preco .parte.um {
  align-items: flex-end;
}

.preco .mensal {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  width: 100%;
  transition: all 0.6s;
}

.preco .anual {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 100%;
  width: 100%;
  transition: all 0.6s;
}

.botoes {
  z-index: 3;
}

.conteudo .botoes .um {
  display: flex !important;
}

.conteudo .botoes .dois {
  display: none !important;
}

.conteudo.ativo .botoes .um {
  display: none !important;
}

.conteudo.ativo .botoes .dois {
  display: flex !important;
}

.parte {
  display: flex;
  position: relative;
  width: 100%;
  z-index: 3;
}
</style>
