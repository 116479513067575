<template>
  <div id="tutoriais">
    <SectionTopo />
    <SectionVideos />
    <Footer />
  </div>
</template>

<script setup>
import SectionTopo from '@components/app/tutoriais/partials/SectionTopo.vue'
import SectionVideos from '@components/app/tutoriais/partials/SectionVideos.vue'
import Footer from '@components/global/footer/Footer.vue'

import { onMounted, inject } from 'vue'
import { useStorePerfil, useStorePages } from '@stores'

const storePerfil = useStorePerfil()
const storePages = useStorePages()
const emitter = inject('emitter')

function carregarPerfil() {
  storePerfil.receberPerfilUsuario()
}

function carregarPages() {
  storePages.receberTodos()
}

onMounted(() => {
  carregarPerfil()
  carregarPages()
  emitter.on('atualizarPages', carregarPages)
})
</script>

<style scoped></style>
