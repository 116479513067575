<!-- HTML -->
<template>
  <div id="registrar">
    <SectionChecklist/>
    <SectionRegistrar />
  </div>
</template>

<!-- VUE -->
<script setup>
import { onMounted } from 'vue'
import { helperToken } from '@helpers'
import SectionChecklist from '@components/autenticacao/criarConta/partials/SectionChecklist.vue'
import SectionRegistrar from '@components/autenticacao/criarConta/partials/SectionRegistrar.vue'

function bloquearRotaSeLogado() {
  if (!helperToken.detectarTokenOk()) return
  window.location.assign('/sites')
}

onMounted(() => {
  bloquearRotaSeLogado()
})

</script>
