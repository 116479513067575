<template>
  <section class="topo">
    <div class="tela">
      <div class="icone">
        <Svgs nome="usuario" />
      </div>
      <p>Seu perfil</p>
    </div>

    <div class="plano">
      <img :src="`https://arquivos.unicpages.com/imagens/app/planos/icones/icone-${storePerfil.usuario?.assinatura?.tipo || 'default'}.svg`" />
      <p v-if="storePerfil.usuario?.assinatura?.tipo === 'test'">Teste Grátis</p>
      <p v-else>
        Assinante
        <span>{{ storePerfil.usuario?.assinatura?.tipo }}</span>
      </p>
    </div>

    <a @click="router.push('/perfil')" class="perfil">
      <div class="texto">
        <h3>{{ storePerfil.usuario?.nome.split(' ')[0] }}</h3>
        <p>{{ storePerfil.usuario?.email }}</p>
      </div>
      <div class="foto" :style="`background-image: url('${storePerfil.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'}')`"></div>
    </a>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import { useStorePerfil } from '@stores'
import Svgs from '@svgs'

const router = useRouter()
const storePerfil = useStorePerfil()
</script>

<style scoped>
section.topo {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px 20px 20px 320px;
  position: relative;
  background-color: var(--cor-cinza-1);
  z-index: 1;
}

.tela {
  display: flex;
  align-items: center;
}

.tela .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
}

.tela .icone svg {
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  fill: var(--cor-branco);
}

.tela p {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 0 0 0 10px;
}

.plano {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 15px 25px 15px 15px;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  margin-left: auto;
}

.plano img {
  width: 100%;
  max-width: 25px;
  margin: 0 10px 0 0;
}

.plano p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.plano p span {
  font-family: var(--bold);
}

.perfil {
  display: flex;
  align-items: center;
  margin: 0 0 0 40px;
}

.perfil .foto {
  width: 40px;
  height: 40px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  border: 2px solid var(--cor-cinza-1);
  transition: all 0.3s;
}

.perfil:hover .foto {
  border: 2px solid var(--cor-azul);
}

.perfil .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 10px 0 0;
}

.perfil .texto h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.perfil .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.topo {
    padding: 80px 20px 20px 20px;
  }

  .tela .icone {
    width: 40px;
    height: 40px;
  }

  .tela .icone svg {
    width: 15px;
    min-width: 15px;
    max-height: 15px;
  }

  .tela p {
    font-size: var(--f2);
  }

  .plano {
    padding: 0;
    margin-left: auto;
  }

  .plano img {
    max-width: 15px;
    margin: 0;
  }

  .plano p {
    display: none;
  }

  .perfil {
    margin: 0 0 0 5px;
  }

  .perfil .foto {
    width: 40px;
    height: 40px;
  }

  .perfil .texto {
    display: none;
  }
}
</style>
