<template>
  <div class="divisor">
    <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    <img :id="elementoDinamico.id" @click="emitirEvento(props.elemento)" :class="{ ocultarElemento: elementoDinamico.hidden }" :src="`${elementoDinamico.image || 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png'}`" />
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['divisor'])

const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('divisor', elemento.elementoSelecionado)
  }
}
</script>

<style>
.divisor {
  z-index: 10;
}
</style>
