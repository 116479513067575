<template>
  <section class="topo" :class="{ ocultarElemento: state.store?.fundo?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarDesktop />
    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <ElementoDivSemFundo class="texto" :store="state.store" :elemento="prepararEvento('fundoTexto')" @secaoSemFundo="secaoSemFundo">
        <div class="tag">
          <ElementoImg :store="state.store" :elemento="prepararEvento('iconeTag')" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento('textoTag')" @texto="texto" />
        </div>

        <ElementoH2 :store="state.store" :elemento="prepararEvento('titulo')" @texto="texto" />
        <ElementoP :store="state.store" :elemento="prepararEvento('subtitulo')" @texto="texto" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botao')" @botao="botao" />
      </ElementoDivSemFundo>

      <ElementoImg :store="state.store" :elemento="prepararEvento('imagemPrincipal')" @imagem="imagem" />

      <ElementoImgAnimada class="animacao um" :store="state.store" :elemento="prepararEvento('imagemAnimadaUm')" @imagemAnimada="imagemAnimada" />
      <ElementoImgAnimada class="animacao dois" :store="state.store" :elemento="prepararEvento('imagemAnimadaDois')" @imagemAnimada="imagemAnimada" />

      <ElementoDivisor :store="state.store" :elemento="prepararEvento('divisor')" @divisor="divisor" class="divisor" />
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarDesktop from '@components/app/criar/partials/EditarDesktop.vue'

import ElementoH2 from '@components/app/criar/elementos/h2.vue'
import ElementoP from '@components/app/criar/elementos/p.vue'
import ElementoButton from '@components/app/criar/elementos/button.vue'
import ElementoImg from '@components/app/criar/elementos/img.vue'
import ElementoDivisor from '@components/app/criar/elementos/divisor.vue'
import ElementoDivSemFundo from '@components/app/criar/elementos/divSemFundo.vue'
import ElementoSecao from '@components/app/criar/elementos/secao.vue'
import ElementoImgAnimada from '@components/app/criar/elementos/imgAnimada.vue'
import ElementoAvisoFixed from '@components/app/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const state = reactive({
  indiceSecao: null,
  store: {}
})

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function imagemAnimada(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagemAnimada', { evento })
}

function divisor(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesDivisor', { evento })
}

function botao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesBotao', { evento })
}

function texto(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesTexto', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function secaoSemFundo(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'desktop'
  }
  return evento
}

function storeDaSecao() {
  const storeDesktop = storeAjustes.secoes.desktop
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeDesktop.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.animacao {
  position: absolute !important;
  z-index: 1 !important;
}

.animacao.um {
  top: -100px;
  left: -100px;
}

.animacao.dois {
  bottom: -100px;
  right: -100px;
}

.texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 4;
}

.texto .tag {
  display: flex;
  align-items: center;
  z-index: 5;
}

.imagem {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
