import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiPages from '@/api/unicpages/api-pages'

export const useStorePages = defineStore('storePages', {
  state: () => {
    return {
      pages: null,
      page: null,
      disponibilidadeHotlink: null
    }
  },
  actions: {
    async receberTodos() {
      try {
        const resp = await apiPages.receberTodos()
        this.pages = resp
        return resp
      } catch (error) {
        this.pages = null
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async receberPorHotlink(hotlink) {
      try {
        const resp = await apiPages.receberPorHotlink(hotlink)
        this.page = resp
        return true
      } catch (error) {
        this.page = null
        return false
      }
    },

    async receberPorId(idPage) {
      try {
        const resp = await apiPages.receberPorId(idPage)
        this.page = resp
        return true
      } catch (error) {
        this.page = null
        return false
      }
    },

    async adicionar(payload) {
      try {
        const resp = await apiPages.adicionar(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDados(payload, suprimirMensagem = false, mensagemCustomizada = '') {
      try {
        const resp = await apiPages.alterarDados(payload)
        const mensagem = mensagemCustomizada ? mensagemCustomizada : resp
        if (suprimirMensagem !== true) useStoreAlerta().exibirSucessoRequest(mensagem)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async deletar(idPage) {
      try {
        const resp = await apiPages.deletar(idPage)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarDominio(payload) {
      try {
        const resp = await apiPages.alterarDominio(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async removerDominio(payload) {
      try {
        const resp = await apiPages.removerDominio(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async publicar(idPage) {
      try {
        const resp = await apiPages.publicar(idPage)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async duplicar(payload) {
      try {
        const resp = await apiPages.duplicar(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async clonarTemplate(payload) {
      try {
        const resp = await apiPages.clonarTemplate(payload)
        useStoreAlerta().exibirSucessoRequest(resp)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    },

    async alterarRascunho(payload, suprimirMensagem = false, mensagemCustomizada = '') {
      try {
        const resp = await apiPages.alterarRascunho(payload)
        const mensagem = mensagemCustomizada ? mensagemCustomizada : resp
        if (suprimirMensagem !== true) useStoreAlerta().exibirSucessoRequest(mensagem)
        return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  },
  getters: {
    urlSite() {
      const dominioPersonalizado = this.page?.dominioPersonalizado
      const hotlink = this.page?.hotlink
      return dominioPersonalizado ? `https://${dominioPersonalizado}` : `https://unic.page/${hotlink}`
    }
  }
})
