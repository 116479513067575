import { api } from './axios'

export default {
  entrar(payload) {
    const method = 'POST'
    const url = 'autenticacao/entrar'
    const dados = payload

    return api(method, url, dados)
  },

  registrar(payload, { plano }) {
    let query = ''
    if (plano === 'test') query = '?plano=test'
    else if (plano === 'gold') query = '?plano=gold'

    const method = 'POST'
    const url = `autenticacao/registrar${query}`
    const data = payload

    return api(method, url, data)
  }
}
