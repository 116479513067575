<template>
  <section class="registrar">
    <Particles />
    <div class="conteudo">
      <h1>Crie sua conta Unic e ganhe 7 dias grátis para construir seus sites 🚀</h1>
      <div class="quadro">
        <div class="titulo">
          <Svgs nome="logo-flat" />
          <p>Crie sua conta e comece a criar sites incríveis</p>
        </div>
        <div class="input">
          <Svgs class="icone" nome="usuario" />
          <input type="text" spellCheck="false" placeholder="Seu nome" autoComplete="name" v-model="state.nome" />
        </div>
        <div class="input">
          <Svgs class="icone" nome="whatsapp" />
          <input type="tel" spellCheck="false" placeholder="(00) 00000-0000" autoComplete="phone" v-model="state.whatsapp" v-mask="mascaraAtual" />
        </div>
        <div class="input">
          <Svgs class="icone" nome="envelope" />
          <input type="email" spellCheck="false" placeholder="email@email.com" autoComplete="email" v-model="state.email" />
        </div>
        <div class="input">
          <Svgs class="icone" nome="cadeado" />
          <input :type="state.mostrarSenha ? 'text' : 'password'" spellCheck="false" placeholder="Crie uma senha" autoComplete="new-password" v-model="state.senha" />
          <button class="ocultar" @click="toggleMostrarSenha">
            <Svgs :nome="state.mostrarSenha ? 'oculto' : 'visivel'" />
          </button>
        </div>
        <div class="input">
          <Svgs class="icone" nome="cadeado" />
          <input :type="state.mostrarSenha ? 'text' : 'password'" spellCheck="false" placeholder="Confirme sua senha" autoComplete="new-password" v-model="state.confirmaSenha" />
        </div>
        <button class="registrar" :disabled="state.desabilitarBotao" @click="criarConta()">
          Liberar 7 dias grátis
          <span></span>
        </button>
        <div class="opcoes">
          <a href="https://wa.me/551831993895">Precisa de ajuda?</a>
          <router-link to="/">Já tem conta? Entre aqui</router-link>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { inject, reactive, computed, onMounted } from 'vue'
import { helperValidacoes } from '@helpers'
import { useRoute } from 'vue-router'
import apiAutenticacao from '@/api/unicpages/api-autenticacao'
import Particles from '@components/global/particles/Particles.vue'
import Svgs from '@svgs'

const emitter = inject('emitter')
const route = useRoute()

const state = reactive({
  nome: '',
  whatsapp: '',
  email: '',
  senha: '',
  confirmaSenha: '',
  origem: '',
  mostrarSenha: false,
  desabilitarBotao: false
})

onMounted(() => {
  const params = [route.query.origem ? `origem = ${route.query.origem}` : '', route.query.utm_campaign ? `utm_campaign = ${route.query.utm_campaign}` : '', route.query.utm_source ? `utm_source = ${route.query.utm_source}` : '', route.query.utm_medium ? `utm_medium = ${route.query.utm_medium}` : '', route.query.utm_content ? `utm_content = ${route.query.utm_content}` : '', route.query.utm_term ? `utm_term = ${route.query.utm_term}` : ''].filter(Boolean).join(', ')

  state.origem = params
})

const toggleMostrarSenha = () => {
  state.mostrarSenha = !state.mostrarSenha
}

const mascaraAtual = computed(() => {
  const digitos = state.whatsapp.replace(/\D/g, '')
  return digitos.length <= 10 ? '(##) ####-####' : '(##) #####-####'
})

function validarDados() {
  let mensagem

  if (!state.nome || !state.whatsapp || !state.email || !state.senha || !state.confirmaSenha) mensagem = 'Por favor preencha todos os campos'
  else if (state.nome.length < 3) mensagem = "O campo 'nome' precisa de no mínimo 3 caracteres"
  else if (!helperValidacoes.email(state.email)) mensagem = 'Formato de email inválido'
  else if (state.whatsapp.length < 15) mensagem = 'Número de telefone inválido'
  else if (state.senha !== state.confirmaSenha) mensagem = 'Senhas não conferem'
  else if (state.senha.length < 6 || state.confirmaSenha.length < 6) mensagem = 'Senha precisa ter 6 caracteres'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

async function criarConta() {
  if (!validarDados()) return

  const payload = {
    nome: state.nome,
    email: state.email,
    senha: state.senha,
    origem: state.origem,
    contato: {
      whatsapp: state.whatsapp
    }
  }

  const payloadLogin = {
    email: state.email,
    senha: state.senha
  }

  try {
    state.desabilitarBotao = true
    await apiAutenticacao.registrar(payload, { plano: 'test' })
    dataLayer.push({
      event: 'fbCompleteRegistration'
    })
    await fazerLogin(payloadLogin)
  } catch (error) {
    state.desabilitarBotao = false
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: error })
  }
}

async function fazerLogin(payload) {
  const resp = await apiAutenticacao.entrar(payload)
  localStorage.setItem('unicpages-app-access-token', resp.token)
  setTimeout(() => window.location.assign('/sites'), 300)
}
</script>

<style scoped>
section.registrar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: var(--cor-cinza-1);
}

.conteudo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100dvh;
  background-color: var(--cor-preto-transparente);
  backdrop-filter: blur(45px);
  -webkit-backdrop-filter: blur(45px);
  padding: 40px;
}

h1 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco-fixo);
  width: 100%;
  max-width: 400px;
  line-height: 1.3;
  margin: 0 50px 0 0;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--cor-cinza-1);
  padding: 60px 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 450px;
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo svg {
  width: 150px;
  min-width: 150px;
  margin: 0 0 15px 0;
}

.conteudo .titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.conteudo .titulo p img {
  width: 15px;
  min-width: 15px;
  margin: 0 5px 0 0;
}

.conteudo .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 300px;
  margin: 30px 0 0 0;
}

.conteudo .input {
  max-width: 400px;
  margin: 20px 0 0 0;
}

label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  width: 100%;
  max-width: 400px;
  text-align: left;
}

.conteudo button.registrar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  width: 100%;
  max-width: 400px;
  padding: 20px 20px;
  border-radius: 10px;
  margin: 20px 0 0 0;
  position: relative;
  transition: all 0.3s;
}

.conteudo button.registrar:hover {
  background-color: var(--cor-azul-escuro);
}

.conteudo button.registrar:disabled {
  color: transparent;
}

.conteudo button.registrar:disabled span {
  opacity: 1;
  visibility: visible;
}

.conteudo button.registrar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco-fixo);
  border-top: 2px solid transparent;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  max-width: 400px;
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border-top: 2px solid var(--cor-cinza-3);
}

.conteudo .opcoes a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: transparent;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.registrar {
    display: flex;
    flex-direction: column-reverse;
  }

  .conteudo {
    width: 100%;
    padding: 50px 20px;
  }

  h1 {
    display: none;
  }

  .quadro {
    padding: 50px 25px;
  }

  .quadro .titulo img {
    max-width: 100px;
  }

  .quadro .titulo p {
    font-size: var(--f1);
  }

  .conteudo .opcoes {
    flex-direction: column;
  }

  .conteudo .opcoes a {
    font-size: var(--f0);
    margin: 0 0 15px 0;
  }
}
</style>
