<template>
  <div class="hidden">
    <h3>
      <span>{{ indiceElementoSelecionado }}ª</span>
      {{ formatarNome(nomeElementoSelecionado) }}
    </h3>
    <div class="toggle" @click="alternarHidden" :class="{ ativo: state.hiddenSelecionado }">
      <p>Visível</p>
      <p>Oculto</p>
      <span></span>
    </div>
  </div>
</template>

<script setup>
import { watchEffect, reactive, computed } from 'vue'
import { useStoreAjustes } from '@stores'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  hiddenSelecionado: false
})

function alternarHidden() {
  state.hiddenSelecionado = state.hiddenSelecionado === false ? true : false
  elementoSelecionado.value.hidden = state.hiddenSelecionado
}

function aplicarHiddenSelecionado() {
  const hidden = elementoSelecionado.value.hidden
  state.hiddenSelecionado = hidden
}

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

const nomeElementoSelecionado = computed(() => {
  const elemento = props.selecao?.elementoSelecionado
  return elemento || ''
})

const indiceElementoSelecionado = computed(() => {
  const indice = props.selecao?.indiceSecao
  return (indice || 0) + 1
})

function formatarNome(nome) {
  return nome
    .replace(/([A-Z])/g, ' $1')
    .replace(/(\d+)/g, ' $1')
    .trim()
}

watchEffect(() => {
  aplicarHiddenSelecionado()
})
</script>

<style scoped>
.hidden {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-bottom: 1px solid var(--cor-cinza-4);
  animation: fadeIn 0.3s ease-in-out;
}
h3 {
  display: flex;
  align-items: center;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: lowercase;
}
h3 span {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
  padding: 2px 0;
  text-align: center;
  min-width: 25px;
  width: 25px;
  margin: 0 5px 0 0;
  border-radius: 5px;
}
.toggle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 110px;
  min-width: 110px;
  height: 25px;
  border-radius: 50px;
  margin: 15px 0 0 0;
  background-color: var(--cor-cinza-3);
  position: relative;
  cursor: pointer;
  transition: all 0.3s;
}

.toggle:hover {
  background-color: var(--cor-cinza-4);
}

.toggle span {
  width: 50%;
  height: 100%;
  background-color: var(--cor-cinza-5);
  border-radius: 50px;
  position: absolute;
  left: 0;
  transition: all 0.3s;
}

.toggle.ativo span {
  left: 50%;
}

.toggle p {
  font-family: var(--bold);
  font-size: var(--f0);
  color: var(--cor-branco-fixo);
  width: 50%;
  text-align: center;
  position: relative;
  z-index: 1;
}
</style>
