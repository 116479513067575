<template>
  <section class="lista">
    <div class="test" v-if="storePerfil.usuario?.assinatura?.tipo === 'test'">
      <img src="https://arquivos.unicpages.com/imagens/app/planos/icones/icone-test.svg" />
      <h3>Você está no teste grátis</h3>
      <p>As ferramentas Unic só estão disponíveis para assinantes Silver, Gold ou Diamond</p>
      <button @click="router.push('/planos')">
        <Svgs nome="estrela" />
        Ver Planos
      </button>
    </div>
    <div class="quadro" v-for="quadro in state.quadros" :key="quadro">
      <div class="capa" :style="`background-image: url('${quadro.capa}')`"></div>
      <div class="texto">
        <h2>{{ quadro.nome }}</h2>
        <p>{{ quadro.descricao }}</p>
      </div>
      <button class="bloqueado" v-if="storePerfil.usuario?.assinatura?.tipo === 'test'">
        <p>Bloqueado</p>
        <Svgs nome="cadeado" />
      </button>
      <button v-else @click="abrirModal(quadro.evento)">
        <p>{{ quadro.botao }}</p>
        <Svgs :nome="quadro.icone" />
      </button>
    </div>
  </section>
</template>

<script setup>
import { reactive } from 'vue'
import { useStoreModal, useStorePerfil } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const storePerfil = useStorePerfil()
const storeModal = useStoreModal()
const router = useRouter()

const state = reactive({
  quadros: [
    {
      nome: 'Criador de Links para Whatsapp',
      descricao: 'Crie links personalizados que vão direto para seu whatsapp com mensagens prontas',
      botao: 'Abrir Ferramenta',
      icone: 'seta',
      capa: 'https://arquivos.unicpages.com/imagens/app/ferramentas/capa.png',
      evento: 'whatsapp'
    },
    {
      nome: 'Gerador de QRCODE',
      descricao: 'Crie QRcode que levam para onde quiser com links personalizados',
      botao: 'Abrir Ferramenta',
      icone: 'seta',
      capa: 'https://arquivos.unicpages.com/imagens/app/ferramentas/capa-qrcode.png',
      evento: 'qrcode'
    }
  ]
})

function abrirModal(evento) {
  if (evento === 'whatsapp') {
    storeModal.abrirModal('geradorLinkWhatsapp', 'whatsapp', 'Gerador de Link')
  }
  if (evento === 'qrcode') {
    storeModal.abrirModal('geradorQrCode', 'ferramentas', 'Gerador de QRcode')
  }
}
</script>

<style scoped>
section.lista {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  padding: 20px 20px 0 320px;
  min-height: 90dvh;
}

.test {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 30px 0 50px 0;
}

.test img {
  width: 100%;
  max-width: 50px;
  margin: 0 0 20px 0;
}

.test h3 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco);
  text-align: center;
}

.test p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  text-align: center;
  line-height: 1.5;
  width: 100%;
  margin: 5px 0 10px 0;
}

.test button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  text-align: center;
  background-color: var(--cor-azul);
  padding: 15px 30px;
  border-radius: 10px;
  transition: all 0.3s;
}

.test button:hover {
  background-color: var(--cor-azul-escuro);
}

.test button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  border-radius: 10px;
  background-color: var(--cor-cinza-2);
  margin: 0 0 20px 0;
}

.quadro .capa {
  width: 100px;
  min-width: 100px;
  height: 100px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
}

.quadro .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-right: auto;
  padding: 0 20px 0 20px;
}

.quadro .texto h2 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
}

.quadro .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 10px 0 0 0;
  line-height: 1.5;
}

.quadro button {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  padding: 15px 15px 15px 20px;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  width: 100%;
  max-width: 200px;
  transition: all 0.3s;
}

.quadro button.bloqueado {
  pointer-events: none;
}

.quadro button:hover {
  background-color: var(--cor-cinza-4);
}

.quadro button svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.lista {
    padding: 20px 20px 0 20px;
  }

  .quadro {
    flex-direction: column;
    align-items: flex-start;
  }

  .quadro .capa {
    width: 90px;
    min-width: 90px;
    height: 90px;
  }

  .test h3 {
    font-size: var(--f4);
  }

  .quadro .texto {
    margin-right: 0;
    padding: 20px 0 10px 0;
  }

  .quadro button {
    max-width: 100%;
  }
}
</style>
