<template>
  <section class="duvida" :class="{ ocultarElemento: state.store?.fundo?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarDesktop />
    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <ElementoDivSemFundo class="texto" :store="state.store" :elemento="prepararEvento('fundoTexto')" @secaoSemFundo="secaoSemFundo">
        <div class="tag">
          <ElementoImg :store="state.store" :elemento="prepararEvento('iconeTag')" @imagem="imagem" />
          <ElementoH2 :store="state.store" :elemento="prepararEvento('textoTag')" @texto="texto" />
        </div>
        <ElementoH2 :store="state.store" :elemento="prepararEvento('titulo')" @texto="texto" />
        <ElementoP :store="state.store" :elemento="prepararEvento('subtitulo')" @texto="texto" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botao')" @botao="botao" />
      </ElementoDivSemFundo>

      <ElementoDivSemFundo class="perguntas" :store="state.store" :elemento="prepararEvento('fundoPerguntas')" @secaoSemFundo="secaoSemFundo">
        <ElementoQuadro v-for="pergunta in perguntas" :class="{ mostrar: state.perguntasAtivas[pergunta] }" :key="pergunta" class="pergunta" :store="state.store" :elemento="prepararEvento(`fundoPergunta${pergunta}`)" @quadro="quadro">
          <div class="nome" @click="togglePergunta(pergunta)">
            <ElementoImg :store="state.store" :elemento="prepararEvento(`iconeTagPergunta${pergunta}`)" @imagem="imagem" />
            <ElementoH2 :store="state.store" :elemento="prepararEvento(`textoTagPergunta${pergunta}`)" @texto="texto" />
          </div>
          <ElementoP class="info" :store="state.store" :elemento="prepararEvento(`textoPergunta${pergunta}`)" @texto="texto" />
        </ElementoQuadro>
      </ElementoDivSemFundo>
      <ElementoDivisor :store="state.store" :elemento="prepararEvento('divisor')" @divisor="divisor" class="divisor" />
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarDesktop from '@components/app/criar/partials/EditarDesktop.vue'

import ElementoH2 from '@components/app/criar/elementos/h2.vue'
import ElementoP from '@components/app/criar/elementos/p.vue'
import ElementoButton from '@components/app/criar/elementos/button.vue'
import ElementoImg from '@components/app/criar/elementos/img.vue'
import ElementoDivisor from '@components/app/criar/elementos/divisor.vue'
import ElementoQuadro from '@components/app/criar/elementos/quadro.vue'
import ElementoSecao from '@components/app/criar/elementos/secao.vue'
import ElementoDivSemFundo from '@components/app/criar/elementos/divSemFundo.vue'
import ElementoAvisoFixed from '@components/app/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const perguntas = ['Um', 'Dois', 'Tres', 'Quatro']

const state = reactive({
  indiceSecao: null,
  store: {},
  perguntasAtivas: {
    Um: false,
    Dois: false,
    Tres: false,
    Quatro: false
  }
})

function togglePergunta(pergunta) {
  state.perguntasAtivas[pergunta] = !state.perguntasAtivas[pergunta]
}

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function divisor(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesDivisor', { evento })
}

function botao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesBotao', { evento })
}

function texto(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesTexto', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function secaoSemFundo(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecaoSemFundo', { evento })
}

function quadro(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesQuadro', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'desktop'
  }
  return evento
}

function storeDaSecao() {
  const storeDesktop = storeAjustes.secoes.desktop
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeDesktop.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  position: relative;
}

.texto .tag {
  display: flex;
  align-items: center;
  z-index: 3;
}

.perguntas {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  width: 100%;
  z-index: 2;
}

.perguntas .pergunta {
  display: flex;
  position: relative;
  background-size: cover;
  background-position: center;
  width: 100%;
  position: relative;
  z-index: 3;
}

.perguntas .pergunta .info {
  display: none;
}

.perguntas .pergunta.mostrar .info {
  display: flex;
  animation: mostrarP 0.3s linear;
}

@keyframes mostrarP {
  0% {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
  }

  100% {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }
}

.perguntas .nome {
  display: flex;
  align-items: center;
  z-index: 3;
}
</style>
