import { api } from './axios'
import { helperToken } from '@helpers'

const token = helperToken.receberToken()

export default {
  chat(payload) {
    const method = 'POST'
    const url = 'gpt/chat'
    const dados = payload

    return api(method, url, dados, token)
  }
}
