<template>
  <Modal nome="editarSite">
    <div class="conteudo">
      <label class="um">
        Nome do site
        <span>*</span>
      </label>
      <input v-model="state.nomeDoSite" type="text" spellcheck="false" placeholder="Ex: Página de Vendas" @keydown.enter="criarSite" />

      <label>
        Descrição do site
        <span>*</span>
      </label>
      <textarea v-model="state.descricaoDoSite" spellcheck="false" placeholder="Ex: Foco em vendas de produtos" @keydown.enter="criarSite"></textarea>

      <label>
        URL do seu site
        <span>*</span>
      </label>
      <div class="inputUrl">
        <p>unic.page/</p>
        <input v-model="state.hotlinkSite" @input="validarHotlink()" spellcheck="false" type="text" placeholder="Digite o hotlink" @keydown.enter="criarSite" />
      </div>

      <label>
        Selecione a cor de destaque
        <span>*</span>
      </label>
      <div class="cores">
        <button v-for="cor in state.cores" :key="cor" @click="selecionarCor(cor)" :class="{ selecionado: state.corDeDestaque === cor }">
          <div class="bolinha" :style="`background-color: #${cor}`"></div>
        </button>
      </div>

      <BotaoSalvar nome="Salvar Alterações" @click="salvarDados" />
    </div>
  </Modal>
</template>

<script setup>
import { reactive, onMounted, inject } from 'vue'
import { useStorePages, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const storePages = useStorePages()
const storeModal = useStoreModal()
const emitter = inject('emitter')

const state = reactive({
  nomeDoSite: '',
  descricaoDoSite: '',
  corDeDestaque: '',
  hotlinkSite: '',
  hotlinkOriginal: '',
  cores: {
    um: 'ff2424',
    dois: 'ff7722',
    tres: 'ffe927',
    quatro: '8fff33',
    cinco: '40ff79',
    seis: '43fffc',
    sete: '2b4eff',
    oito: '7333ff',
    nove: 'd13aff',
    dez: 'ff285d'
  }
})

function validarHotlink() {
  let hotlink = state.hotlinkSite

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlinkSite = hotlink
}

function selecionarCor(cor) {
  state.corDeDestaque = cor
}

async function salvarDados() {
  const hotlinkAlterado = state.hotlinkOriginal !== state.hotlinkSite

  const payload = {
    idPage: storePages.page?._id,
    nome: state.nomeDoSite,
    descricao: state.descricaoDoSite,
    cor: state.corDeDestaque
  }

  if (hotlinkAlterado) payload.hotlink = state.hotlinkSite

  const edicaoOk = await storePages.alterarDados(payload)
  if (edicaoOk !== true) return

  storeModal.fecharModal('editarSite')

  if (hotlinkAlterado) {
    window.location.assign('/criar/' + state.hotlinkSite)
  } else {
    window.location.reload()
  }
}

function preencherDados() {
  state.nomeDoSite = storePages.page?.nome
  state.descricaoDoSite = storePages.page?.descricao
  state.corDeDestaque = storePages.page?.cor
  state.hotlinkSite = storePages.page?.hotlink
  state.hotlinkOriginal = storePages.page?.hotlink
}

function limparCampos() {
  state.nomeDoSite = ''
  state.descricaoDoSite = ''
  state.corDeDestaque = ''
  state.hotlinkSite = ''
  state.hotlinkOriginal = ''
}

onMounted(() => {
  emitter.on('carregarCampos', preencherDados)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

.cores {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.cores button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(10% - 5px);
  height: 40px;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

.cores button .bolinha {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: all 0.3s;
}

.cores button.selecionado {
  pointer-events: none;
  background-color: var(--cor-cinza-4);
}

.cores button.selecionado .bolinha {
  width: 25px;
  height: 25px;
}

.cores button:hover {
  transform: scale(0.9);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
