import { defineStore } from 'pinia'

export const useStoreTemplates = defineStore('storeTemplates', {
  state: () => ({
    mostrarModalCriarComTemplate: false,
    hotlinkTemplate: '',
    imagemCapa: '',
    iconeAutor: '',
    autor: '',
    tipo: ''
  })
})
