<template>
  <div class="tamanhoFonte">
    <h3>Altura da linha</h3>
    <div class="tamanho">
      <input type="tel" v-model="state.valor" class="numero" @input="editandoLineHeight" />
      <p v-if="state.valor !== 'normal'">%</p>
      <input type="range" v-model="state.valor" min="1" max="499" class="mover" @input="editandoLineHeight" />
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  valor: ''
})

function verificarMaximo() {
  if (state.valor > 499) {
    state.valor = 499
  }
}

function carregarLineHeight() {
  state.valor = elementoSelecionado().lineHeight
}

function editandoLineHeight() {
  verificarMaximo()
  elementoSelecionado().lineHeight = state.valor
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  carregarLineHeight()
})
</script>

<style scoped>
.tamanhoFonte {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.tamanho {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
  margin: 5px 0 0 0;
}

.tamanho p {
  position: absolute;
  left: 30px;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  pointer-events: none;
}

input.numero {
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  width: 50px;
  padding: 0;
  transition: all 0.3s;
}

input.numero:focus {
  color: var(--cor-branco);
}

input.numero::-webkit-inner-spin-button,
input.numero::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input.mover {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  background: var(--cor-cinza-3);
  border-radius: 50px;
  outline: none;
  transition: all 0.3s;
}

input.mover:hover::-webkit-slider-thumb {
  background: var(--cor-cinza-5);
}

input.mover:hover::-moz-range-thumb {
  background: var(--cor-cinza-5);
}

input.mover::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid var(--cor-cinza-3);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

input.mover::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid var(--cor-cinza-3);
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}
</style>
