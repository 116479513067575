<template>
  <nav :class="{ ativo: state.menuAberto }">
    <div class="linha"></div>
    <div class="titulo">
      <Svgs nome="logo-flat" @click="router.push('/sites')" />
      <h3>
        Olá
        <span>{{ storePerfil.usuario?.nome.split(' ')[0] }}</span>
        , essa é sua Unic.
      </h3>
      <button class="menu" :class="{ ativo: state.menuAberto }" @click="state.menuAberto = !state.menuAberto" ref="menu">
        <span class="um"></span>
        <span class="dois"></span>
      </button>
    </div>
    <div class="opcoes">
      <a class="opcao" :class="{ ativo: $route.path === '/sites' }" @click="router.push('/sites')">
        <div class="icone">
          <Svgs nome="cursor" />
        </div>
        <p>Sites</p>
      </a>
      <a class="opcao" :class="{ ativo: $route.path === '/templates' }" @click="router.push('/templates')">
        <div class="icone">
          <Svgs nome="template" />
        </div>
        <p>Templates</p>
      </a>
      <a class="opcao" :class="{ ativo: $route.path === '/ferramentas' }" @click="router.push('/ferramentas')">
        <div class="icone">
          <Svgs nome="ferramentas" />
        </div>
        <p>Ferramentas</p>
      </a>
      <a class="opcao" :class="{ ativo: $route.path === '/tutoriais' }" @click="router.push('/tutoriais')">
        <div class="icone">
          <Svgs nome="play" />
        </div>
        <p>Aprenda a usar</p>
      </a>
      <a class="opcao" :class="{ ativo: $route.path === '/planos' }" @click="router.push('/planos')">
        <div class="icone">
          <Svgs nome="estrela" />
        </div>
        <p>Planos</p>
      </a>
      <button class="opcao" :class="{ ativo: $route.path === '/afiliados' }" @click="router.push('/afiliados')">
        <div class="icone">
          <Svgs nome="dinheiro" />
        </div>
        <p>Indique e ganhe</p>
      </button>
      <a class="opcao" href="https://versoes.unicpages.com" target="_blank">
        <div class="icone">
          <Svgs nome="atualizacoes" />
        </div>
        <p>Atualizações</p>
      </a>
      <a class="opcao" href="https://wa.me/551831993895" target="_blank">
        <div class="icone">
          <Svgs nome="whatsapp" />
        </div>
        <p>Suporte no Whatsapp</p>
      </a>
      <a class="perfil" @click="router.push('/perfil')">
        <div class="foto" :style="`background-image: url(${storePerfil.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'})`"></div>
        <div class="texto">
          <h3>
            {{ storePerfil.usuario?.nome.split(' ')[0] }}
            <img :src="`https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-${storePerfil.usuario?.assinatura?.tipo || 'default'}.svg`" />
          </h3>
          <p>{{ storePerfil.usuario?.email }}</p>
        </div>
      </a>
    </div>
  </nav>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { useRouter } from 'vue-router'
import { useStoreSites, useStorePerfil } from '@stores'
import { helperClicouFora } from '@helpers'
import Svgs from '@svgs'

const router = useRouter()
const menu = ref()
const storePerfil = useStorePerfil()
const state = reactive({
  menuAberto: false
})

helperClicouFora.clicouFora([{ alvo: menu, retornoChamada: () => (state.menuAberto = false) }])
</script>

<style scoped>
nav {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100dvh;
  max-height: 100dvh;
  height: 100dvh;
  width: 300px;
  max-width: 300px;
  min-width: 300px;
  background-color: var(--cor-cinza-2);
  z-index: 10;
}

nav .linha {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 3px;
  background: linear-gradient(90deg, var(--degrade));
}

nav .titulo {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  padding: 35px 30px 20px 30px;
}

nav .titulo svg {
  width: 110px;
  min-width: 110px;
  fill: var(--cor-branco);
}

nav .titulo h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 10px 0 0 0;
}

nav .titulo button.menu {
  display: none;
  position: absolute;
  right: 20px;
  width: 35px;
  height: 35px;
  background-color: transparent;
}

nav .titulo button.menu span {
  position: absolute;
  background-color: var(--cor-branco);
  border-radius: 10px;
  height: 3px;
  transition: all 0.5s;
}

nav .titulo button.menu:hover span {
  background-color: var(--cor-cinza-5);
}

nav .titulo button.menu span.um {
  top: 10px;
  right: 0;
  width: 25px;
}

nav .titulo button.menu span.dois {
  bottom: 10px;
  right: 0;
  width: 35px;
}

nav.ativo .titulo button.menu span {
  background-color: var(--cor-vermelho);
}

nav.ativo .titulo button.menu span.um {
  transform: rotate(45deg);
  width: 35px;
  top: 16px;
}

nav.ativo .titulo button.menu span.dois {
  transform: rotate(-45deg);
  bottom: 16px;
}

nav .opcoes {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 100%;
}

nav .opcoes .opcao {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background: transparent;
  width: 100%;
  padding: 8px 30px;
  position: relative;
  transition: all 0.3s;
}

nav .opcoes .opcao .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30px;
  height: 30px;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

nav .opcoes .opcao svg {
  width: 12px;
  min-width: 12px;
  max-height: 12px;
  fill: var(--cor-branco);
  z-index: 1;
}

nav .opcoes .opcao p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  z-index: 1;
  transition: all 0.3s;
}

nav .opcoes .opcao.ativo {
  pointer-events: none;
}

nav .opcoes .opcao.ativo .icone {
  background-color: var(--cor-azul);
}

nav .opcoes .opcao.ativo svg {
  fill: var(--cor-branco-fixo);
}

nav .opcoes .opcao.ativo p {
  color: var(--cor-azul);
}

nav .opcoes .opcao:hover p {
  color: var(--cor-branco);
}

nav a.perfil {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 30px;
  background-color: transparent;
  width: 100%;
  margin-top: auto;
  transition: all 0.3s;
}

nav a.perfil .foto {
  width: 35px;
  height: 35px;
  min-width: 35px;
  border-radius: 10px;
  background-size: cover;
  background-position: center;
  border: 2px solid var(--cor-cinza-1);
  transition: all 0.3s;
}

nav a.perfil:hover .foto {
  border: 2px solid var(--cor-azul);
}

nav a.perfil .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 15px;
}

nav a.perfil .texto h3 {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

nav a.perfil .texto h3 img {
  width: 12px;
  max-width: 12px;
  max-height: 12px;
  margin: 0 0 0 5px;
}

nav a.perfil .texto p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  nav {
    flex-direction: row;
    align-items: center;
    justify-content: center;
    min-height: 60px;
    max-height: 60px;
    height: 60px;
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    border-bottom: 2px solid var(--cor-cinza-1);
  }

  nav .linha {
    height: 2px;
  }

  nav .titulo {
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 2px 20px 0 20px;
    border-bottom: none;
  }

  nav .titulo svg {
    width: 100px;
    min-width: 100px;
  }

  nav .titulo h3 {
    display: none;
  }

  nav .titulo button.menu {
    display: flex;
  }

  nav .opcoes {
    width: 100%;
    padding: 10px 0 10px 0;
    margin-bottom: 0;
    position: fixed;
    top: 55px;
    left: 0;
    background-color: var(--cor-cinza-2);
    height: auto;
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
    transition: all 0.5s;
  }

  nav.ativo .opcoes {
    opacity: 1;
    visibility: visible;
    pointer-events: all;
  }

  nav .opcoes .opcao {
    padding: 8px 20px;
  }

  nav a.perfil {
    padding: 20px;
  }
}
</style>
