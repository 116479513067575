<template>
  <div :id="elementoDinamico.id" :class="{ ocultarElemento: elementoDinamico.hidden }" :style="estiloDinamico">
    <div
      class="background"
      @click="emitirEvento(props.elemento)"
      :style="`
        background: linear-gradient(${elementoDinamico.gradientDeg}deg, #${elementoDinamico.gradientPrimary}, #${elementoDinamico.gradientSecondary});
      `">
      <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['secao'])
const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

const estiloDinamico = computed(() => ({
  flexDirection: elementoDinamico.value.direction,
  alignItems: elementoDinamico.value.alignItems,
  justifyContent: elementoDinamico.value.justifyContent,
  backgroundColor: `#${elementoDinamico.value.backgroundColor}`,
  backgroundImage: `url('${elementoDinamico.value.image || 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png'}')`,
  padding: `${elementoDinamico.value.padding?.top}px ${elementoDinamico.value.padding?.right}px ${elementoDinamico.value.padding?.bottom}px ${elementoDinamico.value.padding?.left}px`
}))

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('secao', elemento.elementoSelecionado)
  }
}
</script>
