import { defineStore } from 'pinia'
import { helperId } from '@helpers'

export const useStoreAjustes = defineStore('storeAjustes', {
  state: () => ({
    secoes: {
      desktop: [],
      mobile: []
    }
  }),
  actions: {
    adicionarSecao(nomeSecao, id, storeComponente, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const novaSecao = gerarObjetoSecao(nomeSecao, id)
      novaSecao.propriedades = storeComponente
      this.secoes[dispositivo].push(novaSecao)
    },

    removerSecao(id, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const indice = this.secoes[dispositivo].findIndex((secao) => secao?.id === id)
      this.secoes[dispositivo].splice(indice, 1)
    },

    duplicarSecao(id, novoId, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const clone = sanitizarSecao(this.secoes[dispositivo])
      const indice = clone.findIndex((secao) => secao?.id === id)

      const temp = sanitizarSecao(clone[indice])
      temp.id = novoId

      const parte1 = clone.slice(0, indice + 1)
      const parte2 = clone.slice(indice + 1)

      this.secoes[dispositivo] = [...parte1, temp, ...parte2]
    },

    replicarParaMobile() {
      const cloneDesktop = sanitizarSecao(this.secoes.desktop)
      const replicaDesktop = cloneDesktop.map((section) => {
        section.id = helperId.random(12)
        section.modelo = section.modelo.replace('Desktop', 'Mobile')

        if (section.propriedades.fundo) {
          section.propriedades.fundo.padding.left = 30
          section.propriedades.fundo.padding.right = 30
          section.propriedades.fundo.direction = 'column'
        }

        if (section.propriedades.fundoItens) {
          section.propriedades.fundoItens.direction = 'column'
          section.propriedades.fundoItens.alignItems = 'center'
        }

        return section
      })
      this.secoes.mobile = replicaDesktop
    },

    moverSecaoAcima(id, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const indice = this.secoes[dispositivo].findIndex((secao) => secao?.id === id)
      if (indice === -1 || indice === 0) return

      const temp = this.secoes[dispositivo][indice]
      this.secoes[dispositivo][indice] = this.secoes[dispositivo][indice - 1]
      this.secoes[dispositivo][indice - 1] = temp
    },

    moverSecaoAbaixo(id, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const indice = this.secoes[dispositivo].findIndex((secao) => secao?.id === id)
      if (indice === -1 || indice === this.secoes[dispositivo].length - 1) return

      const temp = this.secoes[dispositivo][indice]
      this.secoes[dispositivo][indice] = this.secoes[dispositivo][indice + 1]
      this.secoes[dispositivo][indice + 1] = temp
    }
  }
})

function gerarObjetoSecao(nomeSecao, id) {
  return {
    tipo: 'section',
    id: id,
    modelo: nomeSecao,
    propriedades: {}
  }
}

function sanitizarSecao(secao) {
  return JSON.parse(JSON.stringify(secao))
}
