import { defineStore } from "pinia"

export const useStoreSlide1Desktop = defineStore('storeSlide1Desktop', {
  state: () => ({

    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 100,
        right: 0,
        bottom: 140,
        left: 0
      },
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png',
    },

    // Fundo
    fundoItens: {
      id: '',
      hidden: false,
      alignItems: 'flex-start',
      animationDuration: 600,
    },

    // Item Um
    fundoItemUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemUm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o primeiro titulo'
    },

    subtituloItemUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Dois
    fundoItemDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemDois: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o segundo titulo'
    },

    subtituloItemDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Tres
    fundoItemTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemTres: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o terceiro titulo'
    },

    subtituloItemTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Quatro
    fundoItemQuatro: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemQuatro: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o quarto titulo'
    },

    subtituloItemQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Cinco
    fundoItemCinco: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemCinco: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemCinco: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o quinto titulo'
    },

    subtituloItemCinco: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Seis
    fundoItemSeis: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemSeis: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemSeis: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o sexto titulo'
    },

    subtituloItemSeis: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Sete
    fundoItemSete: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemSete: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemSete: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o sétimo titulo'
    },

    subtituloItemSete: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Oito
    fundoItemOito: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemOito: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemOito: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o oitavo titulo'
    },

    subtituloItemOito: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Nove
    fundoItemNove: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemNove: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemNove: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o nono titulo'
    },

    subtituloItemNove: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Dez
    fundoItemDez: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemDez: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemDez: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o décimo titulo'
    },

    subtituloItemDez: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Onze
    fundoItemOnze: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemOnze: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemOnze: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o décimo primeiro titulo'
    },

    subtituloItemOnze: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Item Doze
    fundoItemDoze: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 400,
      margin: {
        top: 0,
        right: 20,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 70,
        right: 70,
        bottom: 70,
        left: 70
      },
    },

    imagemItemDoze: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/imagem-avatar-rosto.png',
      maxWidth: 100,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    tituloItemDoze: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o décimo segundo titulo'
    },

    subtituloItemDoze: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

  })
})
