<template>
  <section class="topo">
    <Particles />
    <div class="conteudo">
      <div class="foto" :style="`background-image: url(${storePerfil.usuario?.imagemPerfil || 'https://arquivos.unicpages.com/imagens/app/icones/placeholder-perfil.svg'})`"></div>
      <div class="texto">
        <h3>{{ storePerfil.usuario?.nome?.split(' ')[0] }}</h3>
        <p>{{ storePerfil.usuario?.email }}</p>
      </div>
      <div class="quadro">
        <img :src="`https://arquivos.unicpages.com/imagens/app/planos/icones/icone-flat-${storePerfil.usuario?.assinatura?.tipo || 'default'}.svg`" />
        <div class="info">
          <h4 v-if="storePerfil.usuario?.assinatura?.tipo === 'test'">Teste Grátis</h4>
          <h4 v-else>
            Assinatura
            <span>{{ storePerfil.usuario?.assinatura?.tipo }}</span>
            {{ storePerfil.usuario?.assinatura?.plano }}
          </h4>
          <p v-if="storePerfil.usuario?.assinatura?.tipo === 'test'">Seu teste grátis acabará em {{ helperData.formatarDataSemHora(storePerfil.usuario?.assinatura?.dataVencimento) }}</p>
          <p v-else>Seu plano será renovado em {{ helperData.formatarDataSemHora(storePerfil.usuario?.assinatura?.dataVencimento) }}</p>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useStorePerfil } from '@stores'
import { helperData } from '@helpers'
import Particles from '@components/global/particles/Particles.vue'

const storePerfil = useStorePerfil()
</script>

<style scoped>
section.topo {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  padding: 0 0 0 300px;
}

.conteudo {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 40px;
  width: 100%;
  background-color: var(--cor-preto-transparente);
  backdrop-filter: blur(45px);
  -webkit-backdrop-filter: blur(45px);
}

.foto {
  width: 100px;
  height: 100px;
  min-width: 100px;
  border-radius: 30px;
  background-size: cover;
  background-position: center;
}

.texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 0 0 0 20px;
  margin-right: auto;
}

.texto h3 {
  font-family: var(--bold);
  font-size: var(--f4);
  color: var(--cor-branco-fixo);
}

.texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  line-height: 1.5;
  margin: 5px 0 0 0;
}

.quadro {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--cor-cinza-1);
  padding: 20px;
  position: relative;
  border-radius: 10px;
}

.quadro img {
  width: 100%;
  max-width: 20px;
  margin: 0 10px 0 0;
}

.quadro .info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.quadro h4 span {
  font-family: var(--bold);
}

.quadro h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  margin: 5px 0 0 0;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.topo {
    padding: 0;
  }

  .conteudo {
    flex-direction: column;
    padding: 50px 20px 20px 20px;
    width: 100%;
  }

  .texto {
    align-items: center;
    padding: 20px 0 40px 0;
    margin-right: 0;
  }

  .quadro {
    justify-content: flex-start;
    width: 100%;
  }

  .quadro img {
    max-width: 15px;
    margin: 0 12px 0 0;
  }

  .quadro h4 {
    font-size: var(--f1);
  }

  .quadro p {
    font-size: var(--f1);
  }
}
</style>
