import { api } from '../../api/unicpages/axios'
import { helperToken } from '../../helpers/index.js'

const idUsuario = helperToken.idUsuario()
const token = helperToken.receberToken()

export default {
  receberOnboarding() {
    if (!idUsuario) return

    const method = 'GET'
    const url = `remote-storage/onboarding/${idUsuario}`

    return api(method, url, null, token)
  },

  alterarOnboarding(payload) {
    if (!idUsuario) return

    const method = 'PUT'
    const url = `remote-storage/onboarding/${idUsuario}`

    return api(method, url, payload, token)
  }
}
