<template>
  <Modal nome="configurarDominio">
    <!-- Primeira etapa -->
    <div class="etapa primeira" v-if="state.etapaUm">
      <div class="itens">
        <div class="quadro">
          <div class="icone">
            <Svgs nome="like" />
          </div>
          <h3>Configuração Fácil</h3>
          <p>Configure seu domínio apenas adicionando um registro A</p>
        </div>
        <div class="quadro">
          <div class="icone">
            <Svgs nome="dominio-seu" />
          </div>
          <h3>Seu domínio é seu</h3>
          <p>Não gerenciamos domínio. Só criamos um registro no seu DNS</p>
        </div>
        <div class="quadro">
          <div class="icone">
            <Svgs nome="servidor" />
          </div>
          <h3>Hospedagem</h3>
          <p>Seu site já está hospedado e ativo na UnicPages</p>
        </div>
        <div class="quadro">
          <div class="icone">
            <Svgs nome="cadeado" />
          </div>
          <h3>Certificado SSL</h3>
          <p>Seu certificado SSL já está ativo e funcionando no seu site Unic</p>
        </div>
      </div>
      <BotaoSalvar @click="avancarDois()" nome="Começar configuração" />
    </div>

    <!-- Segunda etapa -->
    <div class="etapa segunda" v-if="state.etapaDois">
      <a target="_blank" href="https://arquivos.unicpages.com/imagens/app/tutoriais/tutorial-dominio.pdf">
        <Svgs nome="duvida" />
        <p>Como configurar domínio</p>
      </a>
      <label>
        Digite seu domínio
        <span>*</span>
      </label>
      <input type="url" v-model="state.dominioDefinido" autocomplete="false" placeholder="unicpages.com" spellcheck="false" />

      <div class="quadro">
        <div class="icone">
          <Svgs nome="editar" />
        </div>
        <div class="texto">
          <h3>Subdomínios</h3>
          <p>
            Caso queira usar subdomínio digite ele no campo acima. Exemplo: lp.unicpages.com
            <span>(não é necessário digitar www.)</span>
          </p>
        </div>
      </div>
      <div class="quadro">
        <div class="icone">
          <Svgs nome="chave" />
        </div>
        <div class="texto">
          <h3>Tenha acesso</h3>
          <p>Só configure domínios que você tenha acesso. Será necessário configurar o domínio onde comprou.</p>
        </div>
      </div>
      <BotaoSalvar @click="avancarTres()" :nome="`Configurar ${state.dominioDefinido}`" />
    </div>
    <!-- Terceira etapa -->
    <div class="etapa terceira" v-if="state.etapaTres">
      <div class="atual">
        <input type="url" v-model="state.dominioDefinido" readonly />
        <button @click="voltarEtapa()">
          <Svgs nome="editar" />
          <p>Alterar</p>
        </button>
      </div>
      <div class="tutorial">
        <div class="quadro">
          <Svgs class="icone" nome="dominio" />
          <h2>Vá até o local onde foi comprado seu domínio e crie um registro DNS com os dados do quadro ao lado</h2>
          <a target="_blank" href="https://arquivos.unicpages.com/imagens/app/tutoriais/tutorial-dominio.pdf">
            <Svgs nome="duvida" />
            Como configurar
          </a>
        </div>
        <div class="quadro">
          <div class="linha">
            <p>Tipo</p>
            <h3>A</h3>
          </div>
          <div class="linha">
            <p>Nome</p>
            <h3>{{ state.subdominio || '@' }}</h3>
          </div>
          <div class="linha">
            <p>Destino</p>
            <h3 @click="copiarIp()">157.245.90.32</h3>
          </div>
          <div class="linha">
            <p>TTL</p>
            <h3>O menor possível</h3>
          </div>
        </div>
      </div>

      <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
        <span>
          <Svgs nome="check" />
        </span>
        <p>Pronto, fiz o que foi solicitado acima</p>
      </div>

      <BotaoSalvar nome="Salvar meu domínio" :disabled="state.loading" @click="verificarDNS()" />
    </div>

    <!-- Domínio configurado -->
    <div class="etapa configurado" v-if="state.etapaCadastrado">
      <div class="atual">
        <input type="url" v-model="state.dominioDefinido" readonly />
      </div>
      <div class="tutorial">
        <div class="quadro">
          <Svgs class="icone" nome="dominio" />
          <h2>Vá até o local onde foi comprado seu domínio e crie um registro DNS com os dados do quadro ao lado</h2>
          <a target="_blank" href="https://arquivos.unicpages.com/imagens/app/tutoriais/tutorial-dominio.pdf">
            <Svgs nome="duvida" />
            Como configurar
          </a>
        </div>
        <div class="quadro">
          <div class="linha">
            <p>Tipo</p>
            <h3>A</h3>
          </div>
          <div class="linha">
            <p>Nome</p>
            <h3>{{ state.subdominio || '@' }}</h3>
          </div>
          <div class="linha">
            <p>Destino</p>
            <h3 @click="copiarIp()">157.245.90.32</h3>
          </div>
          <div class="linha">
            <p>TTL</p>
            <h3>O menor possível</h3>
          </div>
        </div>
      </div>

      <div class="inputAtivacao" :class="{ ativo: state.confirmar }" @click="state.confirmar = !state.confirmar">
        <span>
          <Svgs nome="check" />
        </span>
        <p>Sim, quero excluir esse domínio</p>
      </div>

      <BotaoExcluir nome="Excluir Domínio" :disabled="state.loading" @click="removerDominio()" />
    </div>
  </Modal>
</template>

<script setup>
import { reactive, inject, onMounted } from 'vue'
import { useStoreDominios, useStorePages } from '@stores'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'
import BotaoExcluir from '@components/global/elementos/BotaoExcluir.vue'

const emitter = inject('emitter')
const storeDominios = useStoreDominios()
const storePages = useStorePages()

const state = reactive({
  etapaUm: true,
  etapaDois: false,
  etapaTres: false,
  etapaCadastrado: false,
  loading: false,
  confirmar: false,
  dominioDefinido: '',
  subdominio: ''
})

function avancarDois() {
  state.etapaUm = false
  state.etapaDois = true
}

function voltarEtapa() {
  state.etapaDois = true
  state.etapaTres = false
}

function extrairSubdominio() {
  const dominio = state.dominioDefinido
  const partes = dominio.split('.')

  if (partes.length > 2) {
    state.subdominio = partes[0]
  } else {
    state.subdominio = ''
  }
}

function avancarTres() {
  if (!state.dominioDefinido) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Preencha o domínio que deseja' })
  } else {
    state.etapaDois = false
    state.etapaTres = true
    extrairSubdominio()
  }
}

async function copiarIp() {
  const url = `157.245.90.32`
  await navigator.clipboard.writeText(url)
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Destino copiado!' })
}

async function verificarDNS() {
  if (!state.confirmar) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Confirme que fez as configurações' })
    return
  }
  state.loading = true

  const dominioOK = await storeDominios.validarDominioDNS(state.dominioDefinido)
  state.loading = false
  if (!dominioOK) return

  state.loading = false
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Domínio configurado!' })

  const payload = {
    dominioPersonalizado: state.dominioDefinido,
    idPage: storePages.page?._id
  }
  const dominioAlteradoOk = await storePages.alterarDominio(payload)
  if (!dominioAlteradoOk) return

  window.location.reload()
}

async function removerDominio() {
  if (!state.confirmar) {
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: 'Marque a confirmação de exclusão' })
  } else {
    const payload = { idPage: storePages.page?._id }
    const dominioRemovidoOk = await storePages.removerDominio(payload)
    if (!dominioRemovidoOk) return

    window.location.reload()
  }
}

function limparCampos() {
  state.dominioDefinido = ''
  state.subdominio = ''
  state.etapaUm = true
  state.etapaDois = false
  state.etapaTres = false
  state.etapaCadastrado = false
  state.loading = false
  state.confirmar = false
}

function verificarDominio() {
  if (!storePages.page?.dominioPersonalizado) {
    state.etapaUm = true
  } else {
    state.dominioDefinido = storePages.page?.dominioPersonalizado
    extrairSubdominio()
    state.etapaUm = false
    state.etapaCadastrado = true
  }
}

onMounted(() => {
  emitter.on('carregarCampos', verificarDominio)
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.etapa {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  position: relative;
  animation: fadeIn 0.3s linear;
}

.primeira .itens {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 100%;
}

.primeira .itens .quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: var(--cor-cinza-3);
  border: 1px solid var(--cor-cinza-3);
  border-radius: 10px;
  width: calc(50% - 10px);
  padding: 30px 20px;
  margin: 0 0 20px 0;
}

.primeira .itens .quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 60px;
  border-radius: 20px;
  border: 2px solid var(--cor-azul);
}

.primeira .itens .quadro .icone svg {
  width: 20px;
  min-width: 20px;
  max-height: 20px;
  fill: var(--cor-branco);
}

.primeira .itens .quadro h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 20px 0 10px 0;
}

.primeira .itens .quadro p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.segunda a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  position: absolute;
  top: 0;
  right: 0;
  transition: all 0.3s;
}

.segunda a:hover {
  opacity: 0.6;
}

.segunda a svg {
  width: 10px;
  min-width: 10px;
  fill: var(--cor-branco);
  margin: 0 5px 0 0;
}

.segunda label {
  margin: 0 0 10px 0;
}

.segunda .quadro {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  margin: 20px 0 0 0;
}

.segunda .quadro .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 20px;
  border: 2px solid var(--cor-azul);
  margin: 0 20px 0 0;
}

.segunda .quadro .icone svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
}

.segunda .quadro .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.segunda .quadro .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.segunda .quadro .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.segunda .quadro .texto p span {
  font-family: var(--bold);
  color: var(--cor-azul);
}

.segunda button {
  margin: 20px 0 0 0;
}

.terceira .atual {
  display: flex;
  width: 100%;
}

.terceira .atual input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul);
  padding: 15px;
  background-color: transparent;
  border: 2px solid var(--cor-azul);
  border-radius: 10px;
}

.terceira .atual button {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
  padding: 15px 20px;
  border-radius: 10px;
  margin: 0 0 0 10px;
  transition: all 0.3s;
}

.terceira .atual button:hover {
  background-color: var(--cor-cinza-4);
}

.terceira .atual button svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-branco);
  margin: 0 10px 0 0;
}

.terceira .tutorial {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 20px 0;
}

.terceira .tutorial .quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(50% - 10px);
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  padding: 30px;
}

.terceira .tutorial .quadro svg.icone {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
}

.terceira .tutorial .quadro h2 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 20px 0 0 0;
}

.terceira .tutorial .quadro a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-laranja);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.terceira .tutorial .quadro a:hover {
  opacity: 0.6;
}

.terceira .tutorial .quadro a svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-laranja);
  margin: 0 10px 0 0;
}

.terceira .tutorial .quadro .linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.terceira .tutorial .quadro .linha p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.terceira .tutorial .quadro .linha h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.configurado .atual {
  display: flex;
  width: 100%;
}

.configurado .atual input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul);
  padding: 15px;
  background-color: transparent;
  border: 2px solid var(--cor-azul);
  border-radius: 10px;
}

.configurado .tutorial {
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: 20px 0 20px 0;
}

.configurado .tutorial .quadro {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: calc(50% - 10px);
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  padding: 30px;
}

.configurado .tutorial .quadro svg.icone {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
}

.configurado .tutorial .quadro h2 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  margin: 20px 0 0 0;
}

.configurado .tutorial .quadro a {
  display: flex;
  align-items: center;
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-laranja);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.configurado .tutorial .quadro a:hover {
  opacity: 0.6;
}

.configurado .tutorial .quadro a svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-laranja);
  margin: 0 10px 0 0;
}

.configurado .tutorial .quadro .linha {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.configurado .tutorial .quadro .linha p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.configurado .tutorial .quadro .linha h3 {
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .terceira .tutorial {
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .terceira .tutorial .quadro {
    width: 100%;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .terceira .tutorial .quadro .linha {
    padding: 10px 0;
  }
  .configurado .tutorial {
    flex-direction: column;
    width: 100%;
    margin: 20px 0 0 0;
  }

  .configurado .tutorial .quadro {
    width: 100%;
    padding: 20px;
    margin: 0 0 20px 0;
  }

  .configurado .tutorial .quadro .linha {
    padding: 10px 0;
  }
}
</style>
