<template>
  <div class="positionFixed">
    <h3>Seção de posição Fixa</h3>
    <h4>Ao definir a seção como fixa, ela ficará sempre no topo da visão do usuário.</h4>
    <div class="botoes">
      <button @click="selecionarPosicao(true)" class="um" :class="{ ativo: state.posicaoFixa === true }">
        <Svgs nome="position-fixed" />
        <p>Ativar seção fixa</p>
      </button>
      <button @click="selecionarPosicao(false)" :class="{ ativo: state.posicaoFixa === false }">
        <Svgs nome="position-normal" />
        <p>Seção normal</p>
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
import Svgs from '@svgs'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  posicaoFixa: false
})

function selecionarPosicao(posicao) {
  state.posicaoFixa = posicao
  elementoSelecionado().positionFixed = posicao
}

function aplicarPosicaoSelecionada() {
  const posicao = elementoSelecionado().positionFixed
  selecionarPosicao(posicao)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  aplicarPosicaoSelecionada()
})
</script>

<style scoped>
.positionFixed {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

h4 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  margin: 0 0 10px 0;
}

.botoes {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
}

.botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 15px 10px;
  width: 100%;
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.botoes button.ativo {
  background-color: var(--cor-cinza-4);
}

.botoes button.um {
  margin: 0 0 10px 0;
}

.botoes button p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  text-align: left;
  transition: all 0.3s;
}

.botoes button.ativo p {
  color: var(--cor-branco);
}

.botoes button:hover p {
  color: var(--cor-branco);
}

.botoes button svg {
  width: 20px;
  min-width: 20px;
  max-height: 15px;
  margin: 0 5px 0 0;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

.botoes button:hover svg {
  fill: var(--cor-branco);
}

.botoes button.ativo svg {
  fill: var(--cor-branco);
}
</style>
