<template>
  <Modal nome="criarDoZero">
    <div class="conteudo">
      <label class="um">
        Nome do site
        <span>*</span>
      </label>
      <input v-model="state.nomeDoSite" type="text" spellcheck="false" placeholder="Ex: Página de Vendas" @keydown.enter="criarSite" />

      <label>
        Descrição do site
        <span>*</span>
      </label>
      <textarea v-model="state.descricaoDoSite" spellcheck="false" placeholder="Ex: Foco em vendas de produtos" @keydown.enter="criarSite"></textarea>

      <label>
        URL do seu site
        <span>*</span>
      </label>
      <div class="inputUrl">
        <p>unic.page/</p>
        <input v-model="state.hotlinkSite" @input="validarHotlink()" spellcheck="false" type="text" placeholder="Digite o hotlink" @keydown.enter="criarSite" />
      </div>

      <label>
        Selecione a cor de destaque
        <span>*</span>
      </label>
      <div class="cores">
        <button v-for="cor in state.cores" :key="cor" @click="selecionarCor(cor)" :class="{ selecionado: state.corDeDestaque === cor }">
          <div class="bolinha" :style="`background-color: #${cor}`"></div>
        </button>
      </div>

      <BotaoSalvar nome="Criar site agora" @click="criarSite" />
    </div>
  </Modal>
</template>
<script setup>
import { inject, reactive, onMounted } from 'vue'
import { useStorePages, useStoreModal } from '@stores'
import Modal from '@components/global/modal/Modal.vue'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const emitter = inject('emitter')
const storePages = useStorePages()
const storeModal = useStoreModal()

const state = reactive({
  nomeDoSite: '',
  descricaoDoSite: '',
  corDeDestaque: '',
  hotlinkSite: '',
  cores: {
    um: 'ff2424',
    dois: 'ff7722',
    tres: 'ffe927',
    quatro: '8fff33',
    cinco: '40ff79',
    seis: '43fffc',
    sete: '2b4eff',
    oito: '7333ff',
    nove: 'd13aff',
    dez: 'ff285d'
  }
})

function validarHotlink() {
  let hotlink = state.hotlinkSite

  hotlink = hotlink.toLowerCase()
  hotlink = hotlink.replace(/\s+/g, '-')
  hotlink = hotlink.replace(/[^a-z0-9-]/g, '')

  state.hotlinkSite = hotlink
}

function selecionarCor(cor) {
  state.corDeDestaque = cor
}

async function criarSite() {
  if (!validarDados()) return

  const payload = {
    nome: state.nomeDoSite,
    descricao: state.descricaoDoSite,
    cor: state.corDeDestaque,
    hotlink: state.hotlinkSite?.trim(),
    metatags: {
      titulo: 'Seu site',
      descricao: 'Site criado com UnicPages',
      favicon: 'https://arquivos.unicpages.com/favicons/favicon.ico'
    },
    codigoFonte: {
      rascunho: {
        desktop: JSON.stringify([]),
        mobile: JSON.stringify([])
      }
    }
  }

  const adicionadoOk = await storePages.adicionar(payload)
  if (adicionadoOk !== true) return

  emitter.emit('atualizarPages')
  storeModal.fecharModal('criarDoZero')
  setTimeout(() => {
    window.location.assign(`/criar/${state.hotlinkSite}`)
  }, 500)
}

function validarDados() {
  let mensagem
  if (!state.nomeDoSite || !state.descricaoDoSite || !state.corDeDestaque || !state.hotlinkSite) mensagem = 'Por favor preencha todos os campos obrigatórios'
  else return true

  emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem: mensagem })
  return false
}

function limparCampos() {
  state.nomeDoSite = ''
  state.descricaoDoSite = ''
  state.corDeDestaque = ''
  state.hotlinkSite = ''
}

onMounted(() => {
  emitter.on('limparCampos', limparCampos)
})
</script>

<style scoped>
.conteudo {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
}

label.um {
  margin: 0 0 10px 0;
}

.cores {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin: 0 0 20px 0;
}

.cores button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: calc(10% - 5px);
  height: 40px;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

.cores button .bolinha {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  transition: all 0.3s;
}

.cores button.selecionado {
  pointer-events: none;
  background-color: var(--cor-cinza-4);
}

.cores button.selecionado .bolinha {
  width: 25px;
  height: 25px;
}

.cores button:hover {
  transform: scale(0.9);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
}
</style>
