<template>
  <section class="modal" :class="{ mostrar: mostrarModalSubir }">
    <div class="conteudo" :class="{ subindo: state.subindoSite, confirmado: state.confirmado }">
      <div class="titulo">
        <Svgs nome="subir" />
        <div class="texto">
          <h3>Subir ajustes para o ar</h3>
          <p>Todos terão acesso as atualizações</p>
        </div>
        <button @click="fecharModal">
          <span class="um"></span>
          <span class="dois"></span>
        </button>
      </div>

      <div class="campos">
        <label>Digite SUBIR abaixo para confirmar</label>
        <input type="text" placeholder="Digite aqui" v-model="state.confirmacao" @keydown.enter="publicarSite" />
      </div>

      <div class="carregando">
        <Svgs nome="subir" />
        <h3>Subindo para o ar</h3>
        <p>{{ texto }}</p>
      </div>

      <div class="sucesso">
        <button class="fechar" @click="fecharModal">
          <span class="um"></span>
          <span class="dois"></span>
        </button>
        <div class="icone">
          <Svgs nome="check" />
          <span></span>
        </div>
        <h3>Seu site está no ar!</h3>
        <p>Todas as atualizações foram para o ar</p>
        <h4>Veja como ficou em:</h4>
        <button class="ver" @click="verSite(page)">
          <Svgs nome="link" />
          {{ page?.dominioPersonalizado ? page.dominioPersonalizado : 'unic.page/' + (page?.hotlink || 'link-site') }}
        </button>
      </div>

      <div class="botao">
        <BotaoSalvar nome="Subir para o ar" @click="publicarSite" />
      </div>
    </div>
  </section>
</template>

<script setup>
import { reactive, inject, onMounted, ref } from 'vue'
import { storeToRefs } from 'pinia'
import { useStoreCriar, useStorePages } from '@stores'
import Svgs from '@svgs'
import BotaoSalvar from '@components/global/elementos/BotaoSalvar.vue'

const emitter = inject('emitter')
const storeCriar = useStoreCriar()
const storePages = useStorePages()

const { page } = storeToRefs(storePages)
const { mostrarModalSubir } = storeToRefs(storeCriar)

function fecharModal() {
  storeCriar.mostrarModalSubir = false
  ;(state.subindoSite = false), (state.confirmado = false), (state.confirmacao = '')
}

const state = reactive({
  subindoSite: false,
  confirmado: false,
  confirmacao: ''
})

function publicarSite() {
  if (state.confirmacao?.toLowerCase()?.trim() !== 'subir') {
    const mensagem = 'É necessário digitar "subir" para enviar as atualizações para o ar'
    emitter.emit('mostrarAlerta', { tipo: 'negativo', mensagem })
    return
  }

  const idPage = storePages.page._id
  state.subindoSite = true

  setTimeout(() => {
    storePages.publicar(idPage)
    state.confirmado = true
    state.subindoSite = false
  }, 4000)
}

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') fecharModal()
})

function verSite(page) {
  const dominioPersonalizado = page?.dominioPersonalizado
  const hotlink = page?.hotlink

  const a = document.createElement('a')
  a.href = dominioPersonalizado ? `https://${dominioPersonalizado}` : `https://unic.page/${hotlink}`
  a.target = '_blank'
  a.click()
}

const palavras = ['Preparando atualizações', 'Seu site na UnicPages']
const indice = ref(0)
const texto = ref('')

onMounted(() => {
  digitar()
})

const digitar = () => {
  const palavraAtual = palavras[indice.value]

  texto.value = ''

  let i = 0
  const intervalo = setInterval(() => {
    texto.value += palavraAtual[i]
    i++

    if (i === palavraAtual.length) {
      clearInterval(intervalo)
      indice.value = (indice.value + 1) % palavras.length
      setTimeout(digitar, 20)
    }
  }, 100)
}
</script>

<style scoped>
section.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100dvh;
  background-color: var(--cor-preto-transparente);
  padding: 30px;
  z-index: 11;
  opacity: 0;
  visibility: visible;
  pointer-events: none;
  transition: all 0.3s;
}

section.modal.mostrar {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

section.modal.fechar {
  opacity: 0;
  visibility: visible;
  pointer-events: none;
}

.conteudo {
  width: 100%;
  max-width: 550px;
  background-color: var(--cor-cinza-1);
  border-radius: 10px;
  position: relative;
  overflow-y: scroll;
}

.conteudo::-webkit-scrollbar-track {
  background-color: transparent;
}

.conteudo .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 30px;
  border-bottom: 2px solid var(--cor-cinza-3);
  transition: all 0.5s;
}

.conteudo .titulo svg {
  width: 30px;
  min-width: 30px;
  fill: var(--cor-branco);
  margin: 0 15px 0 0;
}

.conteudo .titulo .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
}

.conteudo .titulo .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.conteudo .titulo button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 40px;
  height: 40px;
  background: transparent;
  margin-left: auto;
  transition: all 0.5s;
}

.conteudo .titulo button span {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--cor-vermelho);
  transition: all 0.5s;
}

.conteudo .titulo button span.um {
  transform: rotate(45deg);
}

.conteudo .titulo button span.dois {
  transform: rotate(-45deg);
}

.conteudo .titulo button:hover {
  transform: rotate(90deg);
}

.conteudo .titulo button:hover span {
  background-color: var(--cor-branco);
}

.conteudo.subindo .titulo {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.conteudo.subindo .campos {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.conteudo.subindo .botao {
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
}

.conteudo .carregando {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transform: scale(0.8);
  transition: all 0.5s;
}

.conteudo.subindo .carregando {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
  transform: scale(1);
}

.conteudo .carregando svg {
  width: 50px;
  min-width: 50px;
  fill: var(--cor-branco);
  animation: svgCarregando 1.5s ease-in-out infinite;
}

@keyframes svgCarregando {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -20px) scale(1.1);
  }

  100% {
    transform: translate(0, 0);
  }
}

.conteudo .carregando h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 25px 0 10px 0;
}

.conteudo .carregando p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  min-height: 20px;
}

.conteudo .sucesso {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  background-color: var(--cor-cinza-1);
  border-radius: 10px;
  padding: 0 30px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 2;
  transition: all 0.5s;
}

.conteudo.confirmado .sucesso {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.conteudo .sucesso .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
}

.conteudo .sucesso .icone span {
  width: 80px;
  height: 80px;
  background-color: var(--cor-verde);
  box-shadow: 0 0 50px 0 var(--cor-verde);
  opacity: 0.2;
  border-radius: 50%;
  position: absolute;
  z-index: 1;
  animation: spanSucesso 1.5s ease-in-out infinite;
}

@keyframes spanSucesso {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.2);
  }

  100% {
    transform: scale(1);
  }
}

.conteudo .sucesso .icone svg {
  width: 50px;
  min-width: 50px;
  fill: var(--cor-verde);
  z-index: 2;
  animation: svgSucesso 1.5s ease-in-out infinite;
}

@keyframes svgSucesso {
  0% {
    transform: translate(0, 0);
  }

  50% {
    transform: translate(0, -10px) scale(1.1);
  }

  100% {
    transform: translate(0, 0);
  }
}

.conteudo .sucesso h3 {
  font-family: var(--regular);
  font-size: var(--f3);
  color: var(--cor-branco);
  margin: 45px 0 10px 0;
}

.conteudo .sucesso p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
  max-width: 350px;
}

.conteudo .sucesso h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 20px 0 0 0;
}

.conteudo .sucesso button.ver {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-azul);
  margin: 10px 0 0 0;
  transition: all 0.3s;
}

.conteudo .sucesso button.ver svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-azul);
  margin: 0 5px 0 0;
  transition: all 0.3s;
}

.conteudo .sucesso button.ver:hover {
  color: var(--cor-verde);
}

.conteudo .sucesso button.ver:hover svg {
  fill: var(--cor-verde);
}

.conteudo .sucesso button.fechar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 20px;
  right: 20px;
  width: 40px;
  height: 40px;
  background: transparent;
  transition: all 0.5s;
}

.conteudo .sucesso button.fechar span {
  position: absolute;
  width: 40px;
  height: 3px;
  background-color: var(--cor-vermelho);
  transition: all 0.5s;
}

.conteudo .sucesso button.fechar span.um {
  transform: rotate(45deg);
}

.conteudo .sucesso button.fechar span.dois {
  transform: rotate(-45deg);
}

.conteudo .sucesso button.fechar:hover {
  transform: rotate(90deg);
}

.conteudo .sucesso button.fechar:hover span {
  background-color: var(--cor-branco);
}

.campos {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 30px 30px 0 30px;
  transition: all 0.5s;
}

.campos label {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 15px 0;
}

.campos input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  background-color: var(--cor-cinza-2);
  padding: 20px;
  border-radius: 10px;
  width: 100%;
  transition: all 0.3s;
}

.campos input:focus {
  background-color: var(--cor-cinza-3);
}

.botao {
  width: 100%;
  padding: 20px 30px 30px 30px;
  transition: all 0.5s;
}

button.subir {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco);
  background: linear-gradient(90deg, var(--degrade-verde));
  background-size: 100% 100%;
  width: 100%;
  padding: 25px 20px;
  border-radius: 10px;
  position: relative;
  transition: all 0.3s;
}

button.subir:hover {
  background-size: 200% 200%;
}

button.subir:disabled {
  color: transparent;
}

button.subir:disabled span {
  opacity: 1;
  visibility: visible;
}

button.subir span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco);
  border-top: 2px solid transparent;
  box-shadow: 0 5px 30px 0 #ffffff5a;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.modal {
    align-items: flex-start;
    padding: 10px;
  }

  section.modal .titulo {
    padding: 20px;
  }

  section.modal .botao {
    padding: 20px;
  }

  section.modal .campos {
    padding: 20px 20px 0 20px;
  }
}
</style>
