<template></template>

<script setup>
import { onMounted, reactive } from 'vue'
import { useStorePages, useStoreAjustes, useStoreCriar } from '@stores'

const storePages = useStorePages()
const storeCriar = useStoreCriar()
const storeAjustes = useStoreAjustes()

const state = reactive({
  debounceLoad: null,
  id: ''
})

const iniciarAutoLoad = () => {
  storeCriar.mostrarLoading = false
  state.debounceLoad = setInterval(detectarPaginaCarregada, 100)
}

const detectarPaginaCarregada = () => {
  const idPage = storePages.page?._id
  if (!idPage) return

  state.id = idPage
  clearInterval(state.debounceLoad)
  receberRascunho()
}

const receberRascunho = async () => {
  if (!state.id) return

  await storePages.receberPorId(state.id)
  const { desktop, mobile } = storePages.page?.codigoFonte?.rascunho || {}

  if (desktop?.length) carregarSecoes(desktop, true)
  if (mobile?.length) carregarSecoes(mobile, false)

  storeCriar.mostrarLoading = true
}

const carregarSecoes = (secoes, isDesktop) => {
  if (typeof secoes === 'string') {
    try {
      secoes = JSON.parse(secoes)
    } catch (error) {
      console.error('Erro ao fazer parse do JSON:', error)
      return
    }
  }

  if (Array.isArray(secoes)) {
    secoes.forEach((secao) => {
      const { id, modelo: nomeSecao, propriedades: storeComponente } = secao
      storeCriar.adicionarSecao(nomeSecao, id, isDesktop)
      storeAjustes.adicionarSecao(nomeSecao, id, storeComponente, isDesktop)
    })
  } else {
    console.error('Esperava-se um array, mas foi recebido:', secoes)
  }
}

onMounted(iniciarAutoLoad)
</script>
