<template>
  <div class="textDecoration">
    <h3>Decoração do Texto</h3>
    <div class="botoes">
      <button @click="selecionarDecoration('none')" :class="{ ativo: state.decorationSelecionado === 'none' }">
        <Svgs nome="decoration-none" />
      </button>
      <button @click="selecionarDecoration('underline')" :class="{ ativo: state.decorationSelecionado === 'underline' }">
        <Svgs nome="decoration-underline" />
      </button>
      <button @click="selecionarDecoration('line-through')" :class="{ ativo: state.decorationSelecionado === 'line-through' }">
        <Svgs nome="decoration-linethrough" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
import Svgs from '@svgs'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  decorationSelecionado: ''
})

function selecionarDecoration(decoration) {
  state.decorationSelecionado = decoration
  elementoSelecionado().textDecoration = decoration
}

function aplicarAlignSelecionado() {
  const decoration = elementoSelecionado().textDecoration
  selecionarDecoration(decoration)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  aplicarAlignSelecionado()
})
</script>

<style scoped>
.textDecoration {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  width: calc(33.33% - 5px);
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.botoes button.ativo {
  background-color: var(--cor-cinza-4);
}

.botoes button svg {
  width: 20px;
  min-width: 20px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

.botoes button:hover svg {
  fill: var(--cor-branco);
}

.botoes button.ativo svg {
  fill: var(--cor-branco);
}
</style>
