<template>
  <div class="textAlign">
    <h3>Alinhamento</h3>
    <div class="botoes">
      <button @click="selecionarAlign('left')" :class="{ ativo: state.alignSelecionado === 'left' }">
        <Svgs nome="alinhar-esquerda" />
      </button>
      <button @click="selecionarAlign('center')" :class="{ ativo: state.alignSelecionado === 'center' }">
        <Svgs nome="alinhar-centro" />
      </button>
      <button @click="selecionarAlign('right')" :class="{ ativo: state.alignSelecionado === 'right' }">
        <Svgs nome="alinhar-direita" />
      </button>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
import Svgs from '@svgs'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  alignSelecionado: ''
})

function selecionarAlign(align) {
  state.alignSelecionado = align
  elementoSelecionado().textAlign = align
}

function aplicarAlignSelecionado() {
  const align = elementoSelecionado().textAlign
  selecionarAlign(align)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  aplicarAlignSelecionado()
})
</script>

<style scoped>
.textAlign {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}
h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}
.botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: calc(33.33% - 5px);
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}
.botoes button.ativo {
  background-color: var(--cor-cinza-4);
}
.botoes button svg {
  width: 25px;
  min-width: 25px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}
.botoes button:hover svg {
  fill: var(--cor-branco);
}
.botoes button.ativo svg {
  fill: var(--cor-branco);
}
</style>
