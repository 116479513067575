import { defineStore } from 'pinia'
import { helperId } from '@helpers'

export const useStoreCriar = defineStore('storeCriar', {
  state: () => ({
    elementoMarcado: '',
    mostrarLoading: true,
    mostrarModalSecoesMobile: false,
    mostrarModalSecoesDesktop: false,
    mostrarModalExcluirSecaoDesktop: false,
    mostrarModalExcluirSecaoMobile: false,
    mostrarModalInformacoes: false,
    mostrarModalDominio: false,
    mostrarModalConfigurarDominio: false,
    mostrarModalFavicon: false,
    mostrarModalIntegrarFacebook: false,
    mostrarModalIntegrarHotmart: false,
    mostrarModalIntegrarGoogleTag: false,
    mostrarModalIntegrarKiwify: false,
    mostrarModalSubir: false,
    mostrarModalReplicarParaMobile: false,
    mostrarModalCssPersonalizado: false,
    zoom: 0.3,
    dragX: 0,
    dragY: 0,
    arrastando: false,
    startDragX: 0,
    startDragY: 0,
    startMouseX: 0,
    startMouseY: 0,
    fatorZoom: 0.05,
    iniciarArrasteTimeout: null,
    pinca: {
      ativa: false,
      distanciaInicial: 0,
      zoomInicial: 0
    },
    componentes: {
      desktop: [],
      mobile: []
    }
  }),
  actions: {
    adicionarSecao(nomeSecao, id, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      this.componentes[dispositivo].push({ nome: nomeSecao, id: id })
    },

    removerSecao(id, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const indice = this.componentes[dispositivo].findIndex((secao) => secao?.id === id)
      this.componentes[dispositivo].splice(indice, 1)
    },

    duplicarSecao(id, novoId, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const clone = sanitizarSecao(this.componentes[dispositivo])
      const indice = clone.findIndex((secao) => secao?.id === id)

      const temp = sanitizarSecao(clone[indice])
      temp.id = novoId

      const parte1 = clone.slice(0, indice + 1)
      const parte2 = clone.slice(indice + 1)

      this.componentes[dispositivo] = [...parte1, temp, ...parte2]
    },

    replicarParaMobile() {
      const cloneDesktop = sanitizarSecao(this.componentes.desktop)
      const replicaDesktop = cloneDesktop.map((section) => {
        section.id = helperId.random(12)
        section.nome = section.nome.replace('Desktop', 'Mobile')
      })
      this.componentes.mobile = replicaDesktop
    },

    moverSecaoAcima(id, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const indice = this.componentes[dispositivo].findIndex((secao) => secao?.id === id)
      if (indice === -1 || indice === 0) return

      const temp = this.componentes[dispositivo][indice]
      this.componentes[dispositivo][indice] = this.componentes[dispositivo][indice - 1]
      this.componentes[dispositivo][indice - 1] = temp
    },

    moverSecaoAbaixo(id, desktop = true) {
      const dispositivo = desktop === true ? 'desktop' : 'mobile'
      const indice = this.componentes[dispositivo].findIndex((secao) => secao?.id === id)
      if (indice === -1 || indice === this.componentes[dispositivo].length - 1) return

      const temp = this.componentes[dispositivo][indice]
      this.componentes[dispositivo][indice] = this.componentes[dispositivo][indice + 1]
      this.componentes[dispositivo][indice + 1] = temp
    }
  }
})

function sanitizarSecao(secao) {
  return JSON.parse(JSON.stringify(secao))
}
