<template>
  <div :id="elementoDinamico.id" :class="{ ocultarElemento: elementoDinamico.hidden }">
    <div
      class="background"
      @click="emitirEvento(props.elemento)"
      :style="`
        background: linear-gradient(${elementoDinamico.gradientDeg}deg, #${elementoDinamico.gradientPrimary}, #${elementoDinamico.gradientSecondary});
      `">
      <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['html'])
const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('html', elemento.elementoSelecionado)
  }
}
</script>
