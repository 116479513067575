<template>
  <Modal nome="integracoes">
    <button class="botao um" @click="abrirModalGoogleTag()" :class="{ ativo: state.googleTag }">
      <div class="icone">
        <Svgs nome="google" />
      </div>
      <div class="texto">
        <div class="status">
          <span></span>
          <h4>{{ state.googleTag ? 'Instalado' : 'Não instalado' }}</h4>
        </div>
        <h3>Google Tag Manager</h3>
        <p>Instale o script do seu Google Tag</p>
      </div>
      <Svgs class="setinha" nome="setinha" />
    </button>

    <button class="botao" @click="abrirModalFacebook()" :class="{ ativo: state.pixelFacebook }">
      <div class="icone">
        <Svgs nome="meta" />
      </div>
      <div class="texto">
        <div class="status">
          <span></span>
          <h4>{{ state.pixelFacebook ? 'Instalado' : 'Não instalado' }}</h4>
        </div>
        <h3>Pixel Facebook Meta</h3>
        <p>Instale o pixel do seu facebook</p>
      </div>
      <Svgs class="setinha" nome="setinha" />
    </button>
  </Modal>
</template>

<script setup>
import { onMounted, inject, reactive } from 'vue'
import { useStorePages, useStoreModal } from '@stores'
import Svgs from '@svgs'
import Modal from '@components/global/modal/Modal.vue'

const storePages = useStorePages()
const storeModal = useStoreModal()
const emitter = inject('emitter')

const state = reactive({
  googleTag: false,
  pixelFacebook: false
})

function abrirModalGoogleTag() {
  storeModal.fecharModal('integracoes')
  storeModal.abrirModal('googleTag', 'google', 'Configurar Google Tag')
}

function abrirModalFacebook() {
  storeModal.fecharModal('integracoes')
  storeModal.abrirModal('facebookMeta', 'meta', 'Configurar Pixel Facebook')
}

function verificarIntegracoes() {
  if (!storePages.page?.integracoes?.tagManager?.id) {
    state.googleTag = false
  } else {
    state.googleTag = true
  }
  if (!storePages.page?.integracoes?.facebook?.id) {
    state.pixelFacebook = false
  } else {
    state.pixelFacebook = true
  }
}

onMounted(() => {
  emitter.on('carregarCampos', verificarIntegracoes)
})
</script>

<style scoped>
.botao {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: var(--cor-cinza-3);
  border-radius: 10px;
  width: 100%;
  padding: 20px;
  position: relative;
  transition: all 0.3s;
}

.botao:hover {
  background-color: var(--cor-cinza-4);
}

.botao.um {
  margin: 0 0 20px 0;
}

.botao .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 70px;
  min-width: 70px;
  height: 70px;
  border-radius: 20px;
  border: 2px solid var(--cor-vermelho);
  margin: 0 20px 0 0;
}

.botao.ativo .icone {
  border: 2px solid var(--cor-azul);
}

.botao .icone svg {
  width: 25px;
  min-width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
}

.botao .texto {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.botao .texto .status {
  display: flex;
  align-items: center;
  margin: 0 0 10px 0;
}

.botao .texto .status h4 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
}

.botao.ativo .texto .status span {
  background-color: var(--cor-azul);
}

.botao .texto .status span {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: var(--cor-vermelho);
  margin: 0 5px 0 0;
}

.botao .texto h3 {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  margin: 0 0 5px 0;
}

.botao .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

.botao .texto p span {
  font-family: var(--bold);
  color: var(--cor-azul);
}

.botao svg.setinha {
  position: absolute;
  right: 20px;
  width: 15px;
  min-width: 15px;
  fill: var(--cor-cinza-5);
  transform: rotate(-90deg);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .titulo {
    padding: 20px;
  }

  .botao {
    padding: 20px;
  }

  .pixel {
    padding: 20px 20px 0 20px;
  }

  .pixel .linha {
    padding: 0 0 0 10px;
  }
}
</style>
