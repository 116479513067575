import { defineStore } from "pinia"

export const useStoreFormulario4Mobile = defineStore('storeFormulario4Mobile', {
  state: () => ({

    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 60,
        right: 30,
        bottom: 60,
        left: 30
      },
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png',
    },


    // Fundo
    fundoTexto: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 40,
        left: 0
      },
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png',
    },

    // Icone da Tag
    iconeTag: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
    },

    // Texto da Tag
    textoTag: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },

    // Titulo
    titulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo para você preencher'
    },

    // Parágrafo
    subtitulo: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Botão
    botao: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
    },

    // Formulário
    fundoForm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      maxWidth: 800,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 40,
        right: 30,
        bottom: 40,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
    },

    iconeTagForm: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    textoTagForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 300,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },

    tituloForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 25,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Título do form integrado ao Discord'
    },

    subtituloForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para esse formulário integrado com o Discord para receber os dados em um canal'
    },

    botaoForm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'left',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'Link webhook discord aqui',
      linkTarget: '_self',
    },

    labelUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Campo 1'
    },

    labelDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Campo 2'
    },

    labelTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Campo 3'
    },

    labelQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Campo 4'
    },

    labelCinco: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Campo grande 1'
    },

    labelSeis: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Campo 5'
    },

    labelSete: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Campo 6'
    },

    labelOito: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Seletor 1'
    },

    labelNove: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Seletor 2'
    },

    labelDez: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Seletor 3'
    },

    labelOnze: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 10,
        left: 0
      },
      text: 'Campo grande 2'
    },

    // Input

    inputUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
    },

    inputDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
    },

    inputTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
    },

    inputQuatro: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
    },

    inputCinco: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
    },

    inputSeis: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
    },

    // Select

    selectUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Selecionar',
      opcoesSelect: 'Opção 1, Opção 2, Opção 3',
    },

    selectDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 140,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Selecionar',
      opcoesSelect: 'Opção 1, Opção 2, Opção 3',
    },

    selectTres: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 550,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Selecionar',
      opcoesSelect: 'Opção 1, Opção 2, Opção 3',
    },

    // Textarea

    textareaUm: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 550,
      maxHeight: 150,
      lineHeight: 150,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
    },

    textareaDois: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      backgroundColor: 'DDDDDE',
      textAlign: 'left',
      maxWidth: 550,
      maxHeight: 150,
      lineHeight: 150,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      padding: {
        top: 20,
        right: 30,
        bottom: 20,
        left: 30
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      text: 'Digite aqui',
    },

    // Modal

    modal: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 100,
        right: 80,
        bottom: 100,
        left: 80
      },
    },

    fundoTextoModal: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    iconeTagModal: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
    },

    textoTagModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },

    tituloModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 60,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Essa é a página de obrigado'
    },

    subtituloModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 15,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição da página de obrigado. As pessoas vão cair nessa tela assim que o envio do formulário for feito.'
    },

    botaoModal: {
      id: '',
      hidden: false,
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
    },

  })
})
