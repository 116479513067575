<template>
  <div class="animationName">
    <h3>Tipo de animação</h3>
    <div class="botoes">
      <button @click="selecionarName('um')" :class="{ ativo: state.nameSelecionado === 'um' }">1</button>
      <button @click="selecionarName('dois')" :class="{ ativo: state.nameSelecionado === 'dois' }">2</button>
      <button @click="selecionarName('tres')" :class="{ ativo: state.nameSelecionado === 'tres' }">3</button>
      <button @click="selecionarName('quatro')" :class="{ ativo: state.nameSelecionado === 'quatro' }">4</button>
    </div>
  </div>
</template>

<script setup>
import { reactive, watchEffect } from 'vue'
import { useStoreAjustes } from '@stores'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()

const state = reactive({
  nameSelecionado: ''
})

function selecionarName(name) {
  state.nameSelecionado = name
  elementoSelecionado().animationName = name
}

function aplicarNameSelecionado() {
  const name = elementoSelecionado().animationName
  selecionarName(name)
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

watchEffect(() => {
  aplicarNameSelecionado()
})
</script>

<style scoped>
.animationName {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: calc(25% - 5px);
  border-radius: 10px;
  background-color: var(--cor-cinza-3);
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  transition: all 0.3s;
}

.botoes button.ativo {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.botoes button:hover {
  color: var(--cor-branco);
}
</style>
