<template>
  <section class="erro">
    <Particles />
    <div class="conteudo">
      <h1>Criar sites incríveis agora ficou fácil com UnicPages 🏆</h1>
      <div class="quadro">
        <div class="titulo">
          <Svgs nome="logo-flat" />
          <p>Parece que a página que você está tentando acessar não existe. Tente novamente ou chame o time de suporte</p>
        </div>
        <button class="entrar" @click="router.push('/sites')">
          Voltar ao Início
          <span></span>
        </button>
        <div class="opcoes">
          <a href="https://wa.me/551831993895">Falar com Suporte</a>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup>
import { useRouter } from 'vue-router'
import Particles from '@components/global/particles/Particles.vue'
import Svgs from '@svgs'

const router = useRouter()
</script>

<style scoped>
section.erro {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background-size: cover;
  background-position: center;
  background-color: var(--cor-cinza-1);
}

.conteudo {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  width: 100%;
  min-height: 100dvh;
  background-color: var(--cor-preto-transparente);
  backdrop-filter: blur(45px);
  -webkit-backdrop-filter: blur(45px);
}

h1 {
  font-family: var(--bold);
  font-size: var(--f5);
  color: var(--cor-branco-fixo);
  width: 100%;
  max-width: 400px;
  line-height: 1.3;
  margin: 0 50px 0 0;
}

.quadro {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  background-color: var(--cor-cinza-1);
  padding: 60px 40px;
  border-radius: 10px;
  width: 100%;
  max-width: 450px;
}

.conteudo .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.conteudo .titulo svg {
  width: 150px;
  min-width: 150px;
  margin: 0 0 15px 0;
}

.conteudo .titulo p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  line-height: 1.5;
  text-align: center;
  width: 100%;
  max-width: 300px;
}

.conteudo .input {
  margin: 20px 0 0 0;
}

.conteudo button.entrar {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: var(--bold);
  font-size: var(--f2);
  color: var(--cor-branco-fixo);
  background-color: var(--cor-azul);
  width: 100%;
  max-width: 400px;
  padding: 20px 20px;
  border-radius: 10px;
  margin: 20px 0 0 0;
  position: relative;
  transition: all 0.3s;
}

.conteudo button.entrar:hover {
  background-color: var(--cor-azul-escuro);
}

.conteudo button.entrar:disabled {
  color: transparent;
}

.conteudo button.entrar:disabled span {
  opacity: 1;
  visibility: visible;
}

.conteudo button.entrar span {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-branco-fixo);
  border-top: 2px solid transparent;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  animation: girando 0.5s linear infinite;
  pointer-events: none;
  transition: all 0.3s;
}

.conteudo .opcoes {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 400px;
  margin: 20px 0 0 0;
  padding: 20px 0 0 0;
  border-top: 2px solid var(--cor-cinza-3);
}

.conteudo .opcoes a {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: transparent;
  transition: all 0.3s;
}

.conteudo .opcoes a:hover {
  color: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.erro {
    display: flex;
    flex-direction: column-reverse;
  }

  .conteudo {
    width: 100%;
    padding: 50px 20px;
  }

  h1 {
    display: none;
  }

  .quadro {
    padding: 50px 25px;
  }

  .quadro .titulo img {
    max-width: 100px;
  }

  .quadro .titulo p {
    font-size: var(--f1);
  }

  .conteudo .opcoes {
    flex-direction: column;
  }

  .conteudo .opcoes a {
    font-size: var(--f0);
    margin: 0 0 15px 0;
  }
}
</style>
