<template>
  <div class="color">
    <h3>Gradiente de Fundo</h3>

    <div class="botoes">
      <button @click="visibilidade(true), mostrarGradient()" :class="{ ativo: state.ativo === true }">
        <Svgs nome="visivel" />
        Visível
      </button>
      <button @click="visibilidade(false), ocultarGradient()" :class="{ ativo: state.ativo === false }">
        <Svgs nome="oculto" />
        Oculto
      </button>
    </div>

    <div class="configuracoes" v-if="state.ativo">
      <div class="itens">
        <div class="item" :class="{ ativo: state.colorPickerUm.visible }" ref="balaoCoresUm">
          <div class="info" @click="abrirColorPickerUm()">
            <div class="bolinha" :style="`background-color: #${state.corLimpaUm}`"></div>
            <p>#</p>
            <input type="text" v-model="state.corLimpaUm" @input="atualizarCorUm($event.target.value)" />
          </div>
          <div class="balao">
            <div class="titulo">
              <p>Selecione uma cor</p>
              <button class="fechar" @click="fecharColorPickerUm()">
                <Svgs nome="x" />
              </button>
            </div>
            <div class="itens">
              <ColorPicker v-if="state.colorPickerUm.visible" class="colorPicker" theme="dark" :color="state.colorPickerUm.color" :alpha="true" :sucker-hide="false" :sucker-canvas="state.colorPickerUm.suckerCanvas" :sucker-area="state.colorPickerUm.suckerArea" :colors-default="colorsDefault" @changeColor="escolherCorUm" />
            </div>
          </div>
        </div>

        <div class="item" :class="{ ativo: state.colorPickerDois.visible }" ref="balaoCoresDois">
          <div class="info" @click="abrirColorPickerDois()">
            <div class="bolinha" :style="`background-color: #${state.corLimpaDois}`"></div>
            <p>#</p>
            <input type="text" v-model="state.corLimpaDois" @input="atualizarCorDois($event.target.value)" />
          </div>
          <div class="balao">
            <div class="titulo">
              <p>Selecione uma cor</p>
              <button class="fechar" @click="fecharColorPickerDois()">
                <Svgs nome="x" />
              </button>
            </div>
            <div class="itens">
              <ColorPicker v-if="state.colorPickerDois.visible" class="colorPicker" theme="dark" :color="state.colorPickerDois.color" :alpha="true" :sucker-hide="false" :sucker-canvas="state.colorPickerDois.suckerCanvas" :sucker-area="state.colorPickerDois.suckerArea" :colors-default="colorsDefault" @changeColor="escolherCorDois" />
            </div>
          </div>
        </div>
      </div>

      <h3>Posição do Gradiente</h3>

      <div class="tamanho">
        <input type="tel" v-model="state.deg" class="numero" @input="editandoDeg" />
        <p>deg</p>
        <input
          type="range"
          v-model="state.deg"
          min="0"
          max="360"
          class="mover"
          @input="editandoDeg"
          :style="`
        background: linear-gradient(90deg, #${state.corLimpaUm}, #${state.corLimpaDois});
      `" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref, watch, computed, nextTick } from 'vue'
import { ColorPicker } from 'vue-color-kit'
import { useStoreAjustes } from '@stores'
import { helperClicouFora } from '../../../../helpers/index.js'
import Svgs from '@svgs'

const props = defineProps(['selecao'])
const storeAjustes = useStoreAjustes()

const balaoCoresUm = ref(null)
const balaoCoresDois = ref(null)

const state = reactive({
  deg: 0,
  ativo: false,
  colorPickerUm: {
    visible: false,
    color: '',
    suckerCanvas: null,
    suckerArea: [],
    isSucking: true
  },
  colorPickerDois: {
    visible: false,
    color: '',
    suckerCanvas: null,
    suckerArea: [],
    isSucking: true
  },
  corLimpaUm: '',
  corLimpaDois: ''
})

function ocultarGradient() {
  elementoSelecionado.value.gradientPrimary = 'ffffff00'
  elementoSelecionado.value.gradientSecondary = 'ffffff00'
  state.corLimpaUm = 'ffffff00'
  state.corLimpaDois = 'ffffff00'
  state.colorPickerUm.color = 'ffffff00'
  state.colorPickerDois.color = 'ffffff00'
  state.deg = 1
  elementoSelecionado.value.gradientActive = false
}

function mostrarGradient() {
  elementoSelecionado.value.gradientPrimary = 'ffffff'
  elementoSelecionado.value.gradientSecondary = '000000'
  state.corLimpaUm = 'ffffff'
  state.corLimpaDois = '000000'
  state.colorPickerUm.color = 'ffffff'
  state.colorPickerDois.color = '000000'
  state.deg = 90
  elementoSelecionado.value.gradientActive = true
}

const colorsDefault = ['#000000', '#404040', '#808080', '#C0C0C0', '#FFFFFe', '#FF0000', '#FF0000', '#FF3300', '#FF3300', '#FF6600', '#FF6600', '#FF9900', '#FF9900', '#FFCC00', '#FFCC00', '#FFFF00', '#FFFF00', '#CCFF00', '#CCFF00', '#99FF00', '#99FF00', '#66FF00', '#66FF00', '#33FF00', '#33FF00', '#00FF00', '#00FF00', '#00FF33', '#00FF33', '#00FF66', '#00FF66', '#00FF99', '#00FF99', '#00FFCC', '#00FFCC', '#00FFFF', '#00FFFF', '#00CCFF', '#00CCFF', '#0099FF', '#0099FF', '#0066FF', '#0066FF', '#0033FF', '#0033FF', '#0000FF', '#0000FF', '#3300FF', '#3300FF', '#6600FF', '#6600FF', '#9900FF', '#9900FF', '#CC00FF', '#CC00FF', '#FF00FF', '#FF00FF', '#FF00CC', '#FF00CC', '#FF0099', '#FF0099', '#FF0066', '#FF0066', '#FF0033', '#FF0033']

function escolherCorUm(cor) {
  const { r, g, b, a } = cor.rgba
  const hexValue = rgbaToHex(r, g, b, a)
  editarColorUm(hexValue)
  if (state.colorPickerUm.isSucking) {
    const { r, g, b, a } = cor.rgba
    const hexValue = rgbaToHex(r, g, b, a)
    editarColorUm(hexValue)
  }
}

function escolherCorDois(cor) {
  const { r, g, b, a } = cor.rgba
  const hexValue = rgbaToHex(r, g, b, a)
  editarColorDois(hexValue)
  if (state.colorPickerDois.isSucking) {
    const { r, g, b, a } = cor.rgba
    const hexValue = rgbaToHex(r, g, b, a)
    editarColorDois(hexValue)
  }
}

function editarColorUm(valor) {
  state.colorPickerUm.color = '#' + valor
  state.corLimpaUm = valor
  elementoSelecionado.value.gradientPrimary = valor
}

function editarColorDois(valor) {
  state.colorPickerDois.color = '#' + valor
  state.corLimpaDois = valor
  elementoSelecionado.value.gradientSecondary = valor
}

nextTick(() => {
  watch(
    () => ({
      primary: elementoSelecionado.value.gradientPrimary,
      secondary: elementoSelecionado.value.gradientSecondary,
      deg: elementoSelecionado.value.gradientDeg,
      active: elementoSelecionado.value.gradientActive
    }),
    (novasCores) => {
      state.colorPickerUm.color = novasCores.primary
      state.colorPickerDois.color = novasCores.secondary
      state.corLimpaUm = novasCores.primary
      state.corLimpaDois = novasCores.secondary
      state.deg = novasCores.deg
      visibilidade(novasCores.active)
    },
    { immediate: true }
  )
})

function atualizarCorUm(cor) {
  state.corLimpaUm = cor
  elementoSelecionado.value.gradientPrimary = cor
}

function atualizarCorDois(cor) {
  state.corLimpaDois = cor
  elementoSelecionado.value.gradientSecondary = cor
}

function abrirColorPickerUm() {
  state.colorPickerUm.visible = true
  state.colorPickerUm.color = '#' + elementoSelecionado.value.gradientPrimary
}

function abrirColorPickerDois() {
  state.colorPickerDois.visible = true
  state.colorPickerDois.color = '#' + elementoSelecionado.value.gradientSecondary
}

function fecharColorPickerUm() {
  state.colorPickerUm.visible = false
  state.colorPickerUm.color = elementoSelecionado.value.gradientPrimary
}

function fecharColorPickerDois() {
  state.colorPickerDois.visible = false
  state.colorPickerDois.color = elementoSelecionado.value.gradientSecondary
}

function rgbaToHex(r, g, b, a) {
  const alphaHex = Math.round(a * 255)
    .toString(16)
    .padStart(2, '0')
  const redHex = r.toString(16).padStart(2, '0')
  const greenHex = g.toString(16).padStart(2, '0')
  const blueHex = b.toString(16).padStart(2, '0')
  return `${redHex}${greenHex}${blueHex}${alphaHex}`
}

let timeOutId

function verificarMaximo() {
  if (state.deg > 360) {
    state.deg = 360
  }
  if (timeOutId) {
    clearTimeout(timeOutId)
  }
  timeOutId = setTimeout(() => {
    if (state.deg === '') {
      elementoSelecionado.value.gradientDeg = 0
      state.deg = 0
    }
  }, 1000)
}

function editandoDeg() {
  verificarMaximo()
  elementoSelecionado.value.gradientDeg = state.deg
}

function visibilidade(status) {
  state.ativo = status
  elementoSelecionado.value.gradienActive = status
}

const elementoSelecionado = computed(() => {
  const dispositivo = props.selecao?.dispositivo
  const indice = props.selecao?.indiceSecao
  const elemento = props.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
})

helperClicouFora.clicouFora([
  { alvo: balaoCoresUm, retornoChamada: () => (state.colorPickerUm.visible = false) },
  { alvo: balaoCoresDois, retornoChamada: () => (state.colorPickerDois.visible = false) }
])

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') {
    state.colorPickerUm.visible = false
    state.colorPickerDois.visible = false
  }
})
</script>

<style scoped>
.color {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  margin: 0 0 10px 0;
}

.configuracoes {
  width: 100%;
}

.itens {
  margin: 10px 0 15px 0;
  width: 100%;
}

.itens h3 {
  margin: 0 0 8px 0;
}

.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  position: relative;
}

.item .info {
  display: flex;
  align-items: center;
}

.item .info .bolinha {
  width: 20px;
  min-width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid var(--cor-cinza-3);
  background-color: var(--cor-branco);
  cursor: pointer;
  transition: all 0.3s;
}

.item:hover .info .bolinha {
  border: 2px solid var(--cor-branco);
}

.item .info p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  pointer-events: none;
  margin: 0 5px 0 10px;
  transition: all 0.3s;
}

.item .info input {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-branco);
  padding: 5px 0 5px 0;
  text-transform: uppercase;
  width: 100%;
  background-color: var(--cor-cinza-2);
}

.item .info input:focus {
  color: var(--cor-branco);
}

.item .info input::placeholder {
  color: var(--cor-cinza-5);
}

.item .balao {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  background-color: var(--cor-cinza-2);
  bottom: 20px;
  border-radius: 10px;
  right: 290px;
  width: 100%;
  max-width: 300px;
  height: 50dvh;
  max-height: 50dvh;
  min-height: 50dvh;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 4;
  transition: all 0.5s;
}

.item.ativo .balao {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.item .balao .titulo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--cor-cinza-3);
  border-radius: 10px 10px 0 0;
  z-index: 2;
}

.item .balao .titulo p {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
}

.item .balao button.fechar {
  background-color: transparent;
}

.item .balao button.fechar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-vermelho);
  transition: all 0.3s;
}

.item .balao button.fechar:hover svg {
  fill: var(--cor-branco);
}

.item .balao .itens {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 50dvh;
  max-height: 50dvh;
  padding: 40px 20px 20px 20px;
  overflow-y: scroll;
  z-index: 1;
}

.tamanho {
  display: flex;
  align-items: center;
  width: 100%;
  position: relative;
}

.tamanho p {
  position: absolute;
  left: 30px;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  pointer-events: none;
}

input.numero {
  background-color: transparent;
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  width: 60px;
  padding: 0;
  transition: all 0.3s;
}

input.numero:focus {
  color: var(--cor-branco);
}

input.numero::-webkit-inner-spin-button,
input.numero::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input.mover {
  -webkit-appearance: none;
  appearance: none;
  width: 100%;
  height: 20px;
  background: var(--cor-cinza-3);
  border: 2px solid var(--cor-cinza-3);
  border-radius: 50px;
  outline: none;
  transition: all 0.3s;
}

input.mover:hover::-webkit-slider-thumb {
  background: var(--cor-cinza-5);
}

input.mover:hover::-moz-range-thumb {
  background: var(--cor-cinza-5);
}

input.mover::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid transparent;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

input.mover::-moz-range-thumb {
  width: 20px;
  height: 20px;
  background: var(--cor-branco);
  border: 3px solid transparent;
  cursor: pointer;
  border-radius: 50%;
  transition: all 0.3s;
}

.botoes {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.botoes button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  width: calc(50% - 5px);
  border-radius: 10px;
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-cinza-5);
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

.botoes button.ativo {
  color: var(--cor-branco);
  background-color: var(--cor-cinza-4);
}

.botoes button svg {
  width: 25px;
  min-width: 25px;
  max-height: 15px;
  fill: var(--cor-cinza-5);
  transition: all 0.3s;
}

.botoes button:hover svg {
  fill: var(--cor-branco);
}

.botoes button.ativo svg {
  fill: var(--cor-branco);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .item .balao {
    bottom: 20px;
    right: 20px;
    width: 100%;
    max-width: 250px;
    height: 42dvh;
    max-height: 42dvh;
    min-height: 42dvh;
  }
}
</style>
