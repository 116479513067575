<template>
  <div :id="elementoDinamico.id" :class="{ ocultarElemento: elementoDinamico.hidden }" :style="estiloDinamico">
    <div class="background" @click="emitirEvento(props.elemento)">
      <div class="marcar" :class="{ marcado: storeCriar.elementoMarcado === idUnico }"></div>
    </div>
    <slot></slot>
  </div>
</template>

<script setup>
import { computed } from 'vue'
import { useStoreCriar } from '@stores'

const storeCriar = useStoreCriar()

const props = defineProps({
  elemento: Object,
  store: Object
})

const emit = defineEmits(['secaoSemFundo'])
const elementoDinamico = computed(() => props.store?.[props.elemento?.elementoSelecionado] ?? {})
const idUnico = computed(() => props.elemento?.elementoSelecionado + props.elemento?.indiceSecao + props.elemento?.dispositivo ?? {})

const estiloDinamico = computed(() => ({
  flexDirection: elementoDinamico.value.direction,
  alignItems: elementoDinamico.value.alignItems,
  justifyContent: elementoDinamico.value.justifyContent,
  padding: `${elementoDinamico.value.padding?.top}px ${elementoDinamico.value.padding?.right}px ${elementoDinamico.value.padding?.bottom}px ${elementoDinamico.value.padding?.left}px`
}))

function emitirEvento(elemento) {
  const idUnico = elemento.elementoSelecionado + elemento.indiceSecao + elemento.dispositivo
  if (elemento) {
    storeCriar.elementoMarcado = storeCriar.elementoMarcado === idUnico ? '' : idUnico
    emit('secaoSemFundo', elemento.elementoSelecionado)
  }
}
</script>
