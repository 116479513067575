import { defineStore } from 'pinia'
import { useStoreAlerta } from '@stores'
import apiDns from '@/api/unicpages/api-dns'

export const useStoreDominios = defineStore('storeDominios', {
  state: () => ({}),

  actions: {
    async validarDominioDNS(dominio) {
      try {
        const resp = await apiDns.validarDominioDNS(dominio)
        if (!resp) return useStoreAlerta().exibirErroRequest('O domínio pode não ter sido configurado ou aguarde alguns segundos e tente novamente')
        else return true
      } catch (error) {
        return useStoreAlerta().exibirErroRequest(error)
      }
    }
  }
})
