<template>
  <section
    class="nav"
    :class="{ ocultarElemento: state.store?.fundo?.hidden }"
    :style="`
    flex-direction: ${state.store?.fundo?.direction};
  `">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarMobile />
    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <ElementoImg class="logo" :store="state.store" :elemento="prepararEvento('logo')" @imagem="imagem" />
      <div class="opcoes">
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao1')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao2')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao3')" @botao="botao" />
        <ElementoButton :store="state.store" :elemento="prepararEvento('botaoOpcao4')" @botao="botao" />
        <ElementoButton class="destaque" :store="state.store" :elemento="prepararEvento('botaoOpcao5')" @botao="botao" />
      </div>
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarMobile from '@components/app/criar/partials/EditarMobile.vue'

import ElementoButton from '@components/app/criar/elementos/button.vue'
import ElementoImg from '@components/app/criar/elementos/img.vue'
import ElementoSecao from '@components/app/criar/elementos/secao.vue'
import ElementoAvisoFixed from '@components/app/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const state = reactive({
  indiceSecao: null,
  store: {}
})

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function botao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesBotao', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'mobile'
  }
  return evento
}

function storeDaSecao() {
  const storeMobile = storeAjustes.secoes.mobile
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeMobile.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
.opcoes {
  display: flex;
  align-items: center;
}
</style>
