<template>
  <div class="fontFamily">
    <h3>Fonte do texto</h3>
    <div class="select ajusteFlutuante" :class="{ ativo: state.campoFonte }" ref="balaoFontes">
      <div class="campo" @click="state.campoFonte = !state.campoFonte">
        <h5>{{ state.fonteSelecionada?.replace(/-/g, ' ') + ' ' + state.estiloSelecionado || 'Selecionar Fonte' }}</h5>
        <Svgs nome="setinha" />
      </div>
      <div class="lista">
        <div class="titulo">
          <div class="info">
            <p>Google Fonts</p>
            <button class="fechar" @click="state.campoFonte = !state.campoFonte">
              <Svgs nome="x" />
            </button>
          </div>
          <div class="input">
            <Svgs nome="lupa" />
            <input type="text" v-model="state.consultaBusca" placeholder="Buscar fonte" @input="buscarFonte" />
          </div>
        </div>
        <div class="itens">
          <button class="item" :class="{ ativo: state.fonteSelecionada === fonte.family && state.estiloSelecionado === fonte.estilo }" v-for="(fonte, index) in fontesExibidas" :key="`grupo-${index}`" @click="selecionarFonte(fonte.family, fonte.estilo)">
            <p :style="`font-family: '${fonte.family}'; font-weight: ${fonte.estilo}`">{{ `${fonte.family} ${fonte.estilo}` }}</p>
            <span></span>
          </button>
          <button class="carregar" v-if="podeCarregarMais" @click="carregarMaisFontes">Carregar mais</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, watch, watchEffect, ref, onMounted, computed } from 'vue'
import { useStoreAjustes } from '@stores'
import { helperClicouFora } from '../../../../helpers/index.js'
import Svgs from '@svgs'
const props = defineProps(['selecao'])

const storeAjustes = useStoreAjustes()
const balaoFontes = ref(null)

const state = reactive({
  campoFonte: false,
  fonteSelecionada: '',
  estiloSelecionado: '',
  consultaBusca: '',
  indicePagina: 0,
  tamanhoPagina: 20,
  fontesFiltradas: [],
  estilosDisponiveis: []
})

const fontes = ref([])
const API_KEY = 'AIzaSyC2Rumjjhinr8ZRh-4Fsa6bFxWdC6goyzg'

const fontesExibidas = computed(() => {
  const inicio = state.indicePagina * state.tamanhoPagina
  const fim = inicio + state.tamanhoPagina
  return state.fontesFiltradas.flatMap((fonte) => fonte.variants.map((estilo) => ({ family: fonte.family, estilo }))).slice(0, fim)
})

function selecionarFonte(family, estilo) {
  state.fonteSelecionada = family
  state.estiloSelecionado = estilo || '' // Garantir que estilo seja uma string vazia se não estiver definido
  const estiloFormatado = estilo ? estilo.replace(/\s+/g, '') : ''
  const url = estiloFormatado ? `https://fonts.googleapis.com/css2?family=${family.replace(/ /g, '+')}:wght@${estiloFormatado}&display=swap` : `https://fonts.googleapis.com/css2?family=${family.replace(/ /g, '+')}&display=swap`

  const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

  if (!existingLink) {
    const link = document.createElement('link')
    link.href = url
    link.rel = 'stylesheet'
    link.onerror = () => {
      elementoSelecionado().fontFamily = ''
    }
    link.onload = () => {
      elementoSelecionado().fontFamily = estilo ? `${family}, ${estilo}` : family
    }
    document.head.appendChild(link)
  } else {
    elementoSelecionado().fontFamily = estilo ? `${family}, ${estilo}` : family
  }
}

function aplicarFonteSelecionada() {
  const fonteUsada = elementoSelecionado().fontFamily
  const [nomeFonte, estiloFonte] = fonteUsada.split(',').map((item) => item.trim())
  state.fonteSelecionada = nomeFonte
  state.estiloSelecionado = estiloFonte || ''

  selecionarFonte(nomeFonte, estiloFonte || '')
}

function elementoSelecionado() {
  const dispositivo = props?.selecao?.dispositivo
  const indice = props?.selecao?.indiceSecao
  const elemento = props?.selecao?.elementoSelecionado
  return storeAjustes.secoes[dispositivo][indice]?.propriedades[elemento] || {}
}

function carregarMaisFontes() {
  state.indicePagina++
  carregarFontesExibidas()
}

function buscarFonte() {
  state.indicePagina = 0
  if (state.consultaBusca) {
    state.fontesFiltradas = fontes.value.filter((fonte) => fonte.family.toLowerCase().includes(state.consultaBusca.toLowerCase()))
  } else {
    state.fontesFiltradas = fontes.value
  }
  carregarFontesExibidas()
}

const podeCarregarMais = computed(() => {
  return (state.indicePagina + 1) * state.tamanhoPagina < state.fontesFiltradas.length
})

function carregarFontesExibidas() {
  fontesExibidas.value.forEach((fonte) => {
    const url = fonte.estilo ? `https://fonts.googleapis.com/css2?family=${fonte.family.replace(/ /g, '+')}:wght@${fonte.estilo}&display=swap` : `https://fonts.googleapis.com/css2?family=${fonte.family.replace(/ /g, '+')}&display=swap`

    const existingLink = Array.from(document.head.querySelectorAll('link')).find((link) => link.href === url)

    if (!existingLink) {
      const link = document.createElement('link')
      link.href = url
      link.rel = 'stylesheet'
      document.head.appendChild(link)
    }
  })
}

helperClicouFora.clicouFora([{ alvo: balaoFontes, retornoChamada: () => (state.campoFonte = false) }])

document.addEventListener('keydown', (event) => {
  if (event.key === 'Escape') state.campoFonte = false
})

watchEffect(() => {
  carregarFontesExibidas()
})

watch(
  () => props.selecao,
  (newVal, oldVal) => {
    if (newVal !== oldVal) {
      aplicarFonteSelecionada()
    }
  },
  { deep: true }
)

onMounted(() => {
  fetch(`https://www.googleapis.com/webfonts/v1/webfonts?key=${API_KEY}`)
    .then((response) => response.json())
    .then((data) => {
      fontes.value = data.items.map((fonte) => ({
        family: fonte.family,
        variants: fonte.variants.filter((variant) => !variant.includes('italic') && variant !== 'regular')
      }))
      state.fontesFiltradas = fontes.value
      aplicarFonteSelecionada()
    })
    .catch((error) => console.error('Erro ao buscar fontes:', error))
})
</script>

<style scoped>
.fontFamily {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 20px 20px 0 20px;
  animation: fadeIn 0.3s ease-in-out;
}

h3 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.select {
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  margin: 10px 0 0 0;
}

.select .campo {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  background-color: var(--cor-cinza-3);
  padding: 15px;
  width: 100%;
  border-radius: 10px;
  transition: all 0.3s;
}

.select .campo:hover {
  background-color: var(--cor-cinza-4);
}

.select .campo h5 {
  font-family: var(--regular);
  font-size: var(--f1);
  color: var(--cor-branco);
  text-transform: capitalize;
}

.select .campo svg {
  width: 12px;
  min-width: 12px;
  fill: var(--cor-cinza-5);
  transition: all 0.5s;
}

.select.ativo .campo {
  background-color: var(--cor-cinza-4);
}

.select.ativo .campo svg {
  transform: rotate(90deg);
}

.select .lista {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  position: fixed;
  top: 100px;
  right: 270px;
  width: 100%;
  max-width: 250px;
  height: 50dvh;
  max-height: 50dvh;
  min-height: 50dvh;
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 20px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  z-index: 11;
  transition: all 0.5s;
}

.select .lista::-webkit-scrollbar-thumb {
  background-color: var(--cor-cinza-3);
  border-radius: 0 10px 10px 0;
}

.select .lista::-webkit-scrollbar-track {
  background-color: transparent;
}

.select.ativo .lista {
  opacity: 1;
  visibility: visible;
  pointer-events: all;
}

.select .lista .titulo {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  background-color: var(--cor-cinza-2);
  border-radius: 10px 10px 0 0;
  z-index: 2;
}

.select .lista .titulo p {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
}

.select .lista button.fechar {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  padding: 0;
  background-color: transparent;
}

.select .lista button.fechar svg {
  width: 15px;
  min-width: 15px;
  fill: var(--cor-branco);
  position: inherit;
  margin: 0;
  transition: all 0.3s;
}

.select .lista button.fechar:hover svg {
  fill: var(--cor-vermelho);
}

.select .lista .titulo .info {
  display: flex;
  align-items: flex-start;
  width: 100%;
  justify-content: space-between;
}

.select .lista .titulo .input {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  position: relative;
  margin: 10px 0 0 0;
}

.select .lista .titulo .input input {
  padding: 15px 15px 15px 35px;
  color: var(--cor-branco);
  background-color: var(--cor-cinza-3);
}

.select .lista .titulo .input input:focus {
  background-color: var(--cor-cinza-4);
}

.select .lista .titulo .input input::placeholder {
  color: var(--cor-branco);
}

.select .lista .titulo .input svg {
  width: 13px;
  min-width: 13px;
  fill: var(--cor-branco);
  position: absolute;
  left: 15px;
  pointer-events: none;
}

.select .lista .itens {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  height: 50dvh;
  max-height: 50dvh;
  padding: 90px 0 10px 0;
  overflow-y: scroll;
  z-index: 1;
}

.select .lista button.item {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  background-color: transparent;
  padding: 10px 0;
  width: 100%;
  position: relative;
  transition: all 0.3s;
}

.select .lista button.item.ativo {
  pointer-events: none;
}

.select .lista button.item:hover img {
  opacity: 0.4;
}

.select .lista button.item:hover p {
  opacity: 0.6;
}

.select .lista button.item.ativo p {
  color: var(--cor-azul);
}

.select .lista button.item p {
  font-size: var(--f2);
  color: var(--cor-branco);
  z-index: 2;
  position: relative;
  transition: all 0.3s;
}

.select .lista button.item img {
  max-height: 13px;
  max-width: 180px;
  z-index: 2;
  position: relative;
  transition: all 0.3s;
}

.select .lista button.carregar {
  font-family: var(--bold);
  font-size: var(--f1);
  color: var(--cor-branco);
  padding: 10px 25px;
  border-radius: 50px;
  background-color: var(--cor-cinza-3);
  margin: 20px 0 0 0;
  transition: all 0.3s;
}

.select .lista button.carregar:hover {
  background-color: var(--cor-cinza-4);
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  .select .lista {
    top: 120px;
    right: 20px;
    width: 100%;
    max-width: 300px;
    height: 42dvh;
    max-height: 42dvh;
    min-height: 42dvh;
  }

  .select .lista button.item p {
    font-size: var(--f2);
  }
}
</style>
