import { defineStore } from "pinia"

export const useStoreDuvida1Mobile = defineStore('storeDuvida1Mobile', {
  state: () => ({

    // Fundo
    fundo: {
      id: '',
      hidden: false,
      positionFixed: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'DDDDDE',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      padding: {
        top: 90,
        right: 30,
        bottom: 120,
        left: 30
      },
    },

    // Fundo
    fundoTexto: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 30,
        left: 0
      },
    },

    // Divisor
    divisor: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/divisor-1.png',
    },

    // Icone da Tag
    iconeTag: {
      id: '',
      hidden: false,
      link: '',
      linkTarget: '_self',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-solto-preto.svg',
      maxWidth: 20,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
    },

    // Texto da Tag
    textoTag: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 18,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 500,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Site UnicPages'
    },

    // Titulo
    titulo: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 30,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 600,
      margin: {
        top: 20,
        right: 0,
        bottom: 5,
        left: 0
      },
      text: 'Esse é o titulo para você preencher'
    },

    // Parágrafo
    subtitulo: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'center',
      lineHeight: 150,
      maxWidth: 800,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      text: 'E essa é a descrição para usar no seu site da forma mais personalizada possível'
    },

    // Botão
    botao: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: 'ffffff',
      spanBackground: 'ffffff00',
      spanColor: 'ffffff',
      backgroundColor: '000000',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      textAlign: 'center',
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      padding: {
        top: 15,
        right: 30,
        bottom: 15,
        left: 30
      },
      borderRadius: {
        top: 50,
        right: 50,
        bottom: 50,
        left: 50
      },
      text: 'Quero Unic',
      link: 'https://unicpages.com',
      linkTarget: '_self',
    },

    // Fundo
    fundoPerguntas: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    // Pergunta Um
    fundoPerguntaUm: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 1000,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30
      },
    },

    iconeTagPerguntaUm: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-seta-default-preto.png',
      maxWidth: 25,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    textoTagPerguntaUm: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 1000,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Essa é a pergunta do seu site Unic?'
    },

    textoPerguntaUm: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 1000,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Sim, essa é uma seção usada para dúvidas frequentes ou FAQ que ajudará o usuário a entender um certo assunto que ele tem dúvida.'
    },

    // Pergunta Dois
    fundoPerguntaDois: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 1000,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30
      },
    },

    iconeTagPerguntaDois: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-seta-default-preto.png',
      maxWidth: 25,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    textoTagPerguntaDois: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 1000,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Essa é a pergunta do seu site Unic?'
    },

    textoPerguntaDois: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 1000,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Sim, essa é uma seção usada para dúvidas frequentes ou FAQ que ajudará o usuário a entender um certo assunto que ele tem dúvida.'
    },

    // Pergunta Tres
    fundoPerguntaTres: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 1000,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30
      },
    },

    iconeTagPerguntaTres: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-seta-default-preto.png',
      maxWidth: 25,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    textoTagPerguntaTres: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 1000,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Essa é a pergunta do seu site Unic?'
    },

    textoPerguntaTres: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 1000,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Sim, essa é uma seção usada para dúvidas frequentes ou FAQ que ajudará o usuário a entender um certo assunto que ele tem dúvida.'
    },

    // Pergunta Quatro
    fundoPerguntaQuatro: {
      id: '',
      hidden: false,
      direction: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      backgroundColor: 'FFFFFF',
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/vazio.png',
      gradientActive: false,
      gradientPrimary: 'ffffff00',
      gradientSecondary: 'ffffff00',
      gradientDeg: 1,
      maxWidth: 1000,
      margin: {
        top: 0,
        right: 0,
        bottom: 20,
        left: 0
      },
      borderRadius: {
        top: 20,
        right: 20,
        bottom: 20,
        left: 20
      },
      padding: {
        top: 30,
        right: 30,
        bottom: 30,
        left: 30
      },
    },

    iconeTagPerguntaQuatro: {
      id: '',
      hidden: false,
      image: 'https://arquivos.unicpages.com/imagens/app/secoes/icone-seta-default-preto.png',
      maxWidth: 25,
      margin: {
        top: 0,
        right: 15,
        bottom: 0,
        left: 0
      },
      borderRadius: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
    },

    textoTagPerguntaQuatro: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 20,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 1000,
      margin: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Essa é a pergunta do seu site Unic?'
    },

    textoPerguntaQuatro: {
      id: '',
      hidden: false,
      textDecoration: 'none',
      letterSpacing: 0,
      fontFamily: 'Figtree',
      fontSize: 22,
      color: '000000',
      spanBackground: '00000000',
      spanColor: '000000',
      textAlign: 'left',
      lineHeight: 150,
      maxWidth: 1000,
      margin: {
        top: 20,
        right: 0,
        bottom: 0,
        left: 0
      },
      text: 'Sim, essa é uma seção usada para dúvidas frequentes ou FAQ que ajudará o usuário a entender um certo assunto que ele tem dúvida.'
    },

  })
})
