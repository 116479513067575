<template>
  <div class="editar">
    <button class="duplicar" @click="duplicarSecao()">
      <Svgs nome="duplicar-2" />
    </button>
    <button class="deletar" @click="abrirModalExcluirSecaoMobile()">
      <Svgs nome="excluir-2" />
    </button>
    <button class="posicao descer" @click="moverParaBaixo()">
      <Svgs nome="seta" />
    </button>
    <button class="posicao subir" @click="moverParaCima()">
      <Svgs nome="seta" />
    </button>
  </div>
</template>

<script setup>
import { inject, getCurrentInstance } from 'vue'
import { useStoreCriar, useStoreAjustes, useStoreModal } from '@stores'
import { helperId } from '@helpers'
import Svgs from '@svgs'

const currentInstance = getCurrentInstance()
const emitter = inject('emitter')
const storeAjustes = useStoreAjustes()
const storeCriar = useStoreCriar()
const storeModal = useStoreModal()

function abrirModalExcluirSecaoMobile() {
  const id = currentInstance?.proxy?.$el?.parentElement?.id
  storeModal.abrirModalEdicao('excluirSecaoMobile', 'mobile', 'Excluir seção Mobile', id)
}

function duplicarSecao() {
  const id = currentInstance?.proxy?.$el?.parentElement?.id
  const novoId = helperId.random(12)

  storeAjustes.duplicarSecao(id, novoId, false)
  storeCriar.duplicarSecao(id, novoId, false)
  emitter.emit('renderizarComponentesTela')
}

function moverParaCima() {
  const id = currentInstance?.proxy?.$el?.parentElement?.id
  storeAjustes.moverSecaoAcima(id, false)
  storeCriar.moverSecaoAcima(id, false)
  emitter.emit('renderizarComponentesTela')
}

function moverParaBaixo() {
  const id = currentInstance?.proxy?.$el?.parentElement?.id
  storeAjustes.moverSecaoAbaixo(id, false)
  storeCriar.moverSecaoAbaixo(id, false)
  emitter.emit('renderizarComponentesTela')
}
</script>

<style scoped>
.editar {
  display: flex;
  align-items: center;
  position: absolute;
  left: -400px;
  top: 50%;
  transform: translate(0, -50%);
}
button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-color: var(--cor-cinza-2);
  margin: 0 0 0 10px;
  transition: all 0.3s;
}
button svg {
  width: 30px;
  min-width: 30px;
  max-height: 30px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}
button.deletar:hover svg {
  fill: var(--cor-vermelho);
}
button.duplicar:hover svg {
  fill: var(--cor-azul);
}
button.posicao:hover svg {
  fill: var(--cor-laranja);
}
button.posicao.descer svg {
  transform: rotate(90deg);
}
button.posicao.subir svg {
  transform: rotate(-90deg);
}
</style>
