<template>
  <section class="slide" :class="{ ocultarElemento: state.store?.fundo?.hidden }">
    <ElementoAvisoFixed v-if="state.store?.fundo?.positionFixed" />
    <EditarDesktop />

    <div class="slideExterno">
      <div class="tamanhoFixo">
        <div
          class="itens"
          :style="`
            align-items: ${state.store?.fundoItens?.alignItems};
            justify-content: ${state.store?.fundoItens?.justifyContent};
          `">
          <div class="background" @click="animacao('fundoItens')" data-name="fundoItens">
            <div class="marcar"></div>
          </div>

          <ElementoQuadroFixo v-for="item in items" :key="item" class="item" :store="state.store" :elemento="prepararEvento(`fundoItem${item}`)" @quadro="quadro">
            <ElementoImg :store="state.store" :elemento="prepararEvento(`imagemItem${item}`)" @imagem="imagem" />
            <ElementoH2 :store="state.store" :elemento="prepararEvento(`tituloItem${item}`)" @texto="texto" />
            <ElementoP :store="state.store" :elemento="prepararEvento(`subtituloItem${item}`)" @texto="texto" />
          </ElementoQuadroFixo>
        </div>
      </div>
    </div>

    <button class="pausar" @click="state.animacao = !state.animacao">
      <Svgs :nome="state.animacao ? 'pausar' : 'playsolto'" />
    </button>
    <ElementoSecao class="conteudo" :store="state.store" :elemento="prepararEvento('fundo')" @secao="secao">
      <div class="slide">
        <div
          class="tamanho"
          :class="{ pausar: state.animacao === false }"
          :style="`
          animation-duration: ${state.store?.fundoItens?.animationDuration}s;
        `">
          <div
            class="itens"
            :style="`
            align-items: ${state.store?.fundoItens?.alignItems};
            justify-content: ${state.store?.fundoItens?.justifyContent};
          `">
            <div class="background" @click="animacao('fundoItens')" data-name="fundoItens">
              <div class="marcar"></div>
            </div>

            <ElementoQuadroFixo v-for="item in items" :key="item" class="item" :store="state.store" :elemento="prepararEvento(`fundoItem${item}`)" @quadro="quadro">
              <ElementoImg :store="state.store" :elemento="prepararEvento(`imagemItem${item}`)" @imagem="imagem" />
              <ElementoH2 :store="state.store" :elemento="prepararEvento(`tituloItem${item}`)" @texto="texto" />
              <ElementoP :store="state.store" :elemento="prepararEvento(`subtituloItem${item}`)" @texto="texto" />
            </ElementoQuadroFixo>
          </div>
        </div>
      </div>

      <ElementoDivisor :store="state.store" :elemento="prepararEvento('divisor')" @divisor="divisor" class="divisor" />
    </ElementoSecao>
  </section>
</template>

<script setup>
import { inject, getCurrentInstance, onMounted, reactive } from 'vue'
import { useStoreAjustes } from '@stores'
import EditarDesktop from '@components/app/criar/partials/EditarDesktop.vue'
import Svgs from '@svgs'

import ElementoH2 from '@components/app/criar/elementos/h2.vue'
import ElementoP from '@components/app/criar/elementos/p.vue'
import ElementoImg from '@components/app/criar/elementos/img.vue'
import ElementoDivisor from '@components/app/criar/elementos/divisor.vue'
import ElementoQuadroFixo from '@components/app/criar/elementos/quadroFixo.vue'
import ElementoSecao from '@components/app/criar/elementos/secao.vue'
import ElementoAvisoFixed from '@components/app/criar/elementos/avisoFixed.vue'

const storeAjustes = useStoreAjustes()
const currentInstance = getCurrentInstance()

const emitter = inject('emitter')

const items = ['Um', 'Dois', 'Tres', 'Quatro', 'Cinco', 'Seis', 'Sete', 'Oito', 'Nove', 'Dez', 'Onze', 'Doze']

const state = reactive({
  indiceSecao: null,
  store: {},
  animacao: true
})

function imagem(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesImagem', { evento })
}

function divisor(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesDivisor', { evento })
}

function texto(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesTexto', { evento })
}

function quadro(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesQuadro', { evento })
}

function secao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesSecao', { evento })
}

function animacao(elemento) {
  const evento = prepararEvento(elemento)
  emitter.emit('mostrarAjustesAnimacao', { evento })
}

function prepararEvento(elemento) {
  storeDaSecao()
  const evento = {
    elementoSelecionado: elemento,
    indiceSecao: state.indiceSecao,
    dispositivo: 'desktop'
  }
  return evento
}

function storeDaSecao() {
  const storeDesktop = storeAjustes.secoes.desktop
  const idSecao = currentInstance?.proxy?.$el?.id

  for (const [indice, secao] of storeDesktop.entries()) {
    if (secao.id !== idSecao) continue

    state.store = secao?.propriedades
    state.indiceSecao = indice
    break
  }
}

onMounted(() => {
  storeDaSecao()
})
</script>

<style scoped>
button.pausar {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: -127px;
  top: calc(50% - 90px);
  transform: translate(0, -50%);
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-color: var(--cor-cinza-2);
  margin: 0 10px 0 0;
  transition: all 0.3s;
}

button.pausar:hover {
  background-color: var(--cor-cinza-3);
  border: none !important;
}

button svg {
  width: 30px;
  min-width: 30px;
  max-height: 35px;
  fill: var(--cor-branco);
}

.slideExterno {
  display: flex;
  position: absolute;
  right: 1620px;
  top: 50%;
  transform: translate(0, -50%);
}

.slide {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  position: relative;
  z-index: 3;
}

.tamanho.pausar {
  animation-play-state: paused;
}

.tamanhoFixo {
  display: flex;
}

.tamanho {
  display: flex;
  animation-name: mexendoSlide;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-direction: normal;
  animation-iteration-count: infinite;
}

@keyframes mexendoSlide {
  0% {
    transform: translate(0%, 0);
  }

  50% {
    transform: translate(-100%, 0);
  }

  100% {
    transform: translate(0%, 0);
  }
}

.itens {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 4;
}

.item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 5;
}
</style>
