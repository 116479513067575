<template>
  <MonacoEditor v-model:value="internalValue" :language="language" :theme="theme" :options="editorOptions" />
</template>

<script setup>
import { ref, watch, toRefs } from 'vue'
import MonacoEditor from '@guolao/vue-monaco-editor'

const props = defineProps({
  modelValue: String,
  language: {
    type: String,
    default: 'html'
  },
  theme: {
    type: String,
    default: 'vs-dark'
  }
})

const emit = defineEmits(['update:modelValue'])

const { modelValue } = toRefs(props)

const internalValue = ref(modelValue.value)

const editorOptions = {
  tabSize: 2,
  insertSpaces: true,
  detectIndentation: false,
  automaticLayout: true,
  lineNumbers: 'on',
  scrollBeyondLastLine: true,
  minimap: {
    enabled: true
  }
}

watch(modelValue, (newValue) => {
  internalValue.value = newValue
})

watch(internalValue, (newValue) => {
  emit('update:modelValue', newValue)
})
</script>
