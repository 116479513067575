import { onMounted, onBeforeUnmount } from 'vue'

function clicouFora(alvosEFuncoes) {
  if (!alvosEFuncoes || !alvosEFuncoes.length) return

  // Define o ouvinte de eventos
  const ouvinte = (e) => {
    alvosEFuncoes.forEach(({ alvo, retornoChamada }) => {
      // Verifica se o elemento alvo está disponível e contém o clique
      if (alvo && alvo.value && alvo.value.contains) {
        if (e.target !== alvo.value && !alvo.value.contains(e.target)) {
          if (typeof retornoChamada === 'function') {
            retornoChamada()
          }
        }
      }
    })
  }

  // Adiciona o ouvinte de eventos quando o componente é montado
  onMounted(() => {
    window.addEventListener('click', ouvinte)
  })

  // Remove o ouvinte de eventos antes do componente ser desmontado
  onBeforeUnmount(() => {
    window.removeEventListener('click', ouvinte)
  })

  return { ouvinte }
}

export default { clicouFora }
