<template>
  <section class="criar">
    <button @click="alterarTema()">
      <div class="icone">
        <Svgs nome="modo" />
      </div>
      <div class="texto">
        <h3>Tema {{ textoTema() }}</h3>
        <p>Personalize o tema da Unic</p>
      </div>
    </button>
    <button @click="abrirModalAlterarSenha('alterarSenha', 'cadeado', 'Alterar senha')">
      <div class="icone">
        <Svgs nome="cadeado" />
      </div>
      <div class="texto">
        <h3>Alterar senha</h3>
        <p>Edite sua senha aqui</p>
      </div>
    </button>
    <button @click="abrirModalEditarPerfil('editarPerfil', 'editar', 'Editar perfil')">
      <div class="icone">
        <Svgs nome="usuario" />
      </div>
      <div class="texto">
        <h3>Editar perfil</h3>
        <p>Altere suas informações</p>
      </div>
    </button>
    <button @click="logout">
      <div class="icone">
        <Svgs nome="sair" />
      </div>
      <div class="texto">
        <h3>Sair da Conta</h3>
        <p>Deslogue de sua conta</p>
      </div>
    </button>
  </section>
</template>

<script setup>
import { reactive, inject } from 'vue'
import { useStoreModal } from '@stores'
import { useRouter } from 'vue-router'
import Svgs from '@svgs'

const emitter = inject('emitter')
const router = useRouter()
const storeModal = useStoreModal()

const state = reactive({
  tema: JSON.parse(localStorage.getItem('personalizacao-unicpages'))?.tema
})

function abrirModalAlterarSenha(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
}

function abrirModalEditarPerfil(nome, icone, titulo) {
  storeModal.abrirModal(nome, icone, titulo)
}

function logout() {
  emitter.emit('mostrarAlerta', { tipo: 'positivo', mensagem: 'Você saiu de sua conta Unic' })
  localStorage.removeItem('unicpages-app-access-token')
  router.push('/')
}

function textoTema() {
  if (state.tema === 'claro') return 'Modo Escuro'
  else if (state.tema === 'escuro') return 'Modo Claro'
  else return 'tema'
}

function alterarTema() {
  const dadosLocalStorage = JSON.parse(localStorage.getItem('personalizacao-unicpages')) || {}

  state.tema = state.tema === 'claro' ? 'escuro' : 'claro'

  localStorage.setItem('personalizacao-unicpages', JSON.stringify({ ...dadosLocalStorage, tema: state.tema }))

  document.body.setAttribute('tema', state.tema)
}
</script>

<style scoped>
section.criar {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 0 20px 10px 320px;
  position: relative;
  background-color: var(--cor-cinza-1);
  min-height: 70dvh;
  z-index: 1;
}

button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: calc(25% - 15px);
  background-color: var(--cor-cinza-2);
  border-radius: 10px;
  padding: 150px 20px;
  margin: 30px 0 0 0;
  transition: all 0.3s;
}

button.breve {
  pointer-events: none;
  opacity: 0.4;
}

button:hover {
  background-color: var(--cor-cinza-3);
}

button .texto {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  margin: 20px 0 0 0;
}

button .texto h3 {
  font-family: var(--bold);
  font-size: var(--f3);
  color: var(--cor-branco);
  line-height: 1.5;
}

button .texto p {
  font-family: var(--regular);
  font-size: var(--f2);
  color: var(--cor-cinza-5);
  line-height: 1.5;
}

button .icone {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 80px;
  height: 80px;
  border-radius: 20px;
  background-color: var(--cor-cinza-3);
  transition: all 0.3s;
}

button:hover .icone {
  background-color: var(--cor-cinza-4);
}

button .icone svg {
  min-width: 25px;
  width: 25px;
  max-height: 25px;
  fill: var(--cor-branco);
  transition: all 0.3s;
}

/* Responsivo */
@media screen and (max-width: 1024px) {
  section.criar {
    flex-direction: column;
    padding: 0 20px 10px 20px;
  }

  button {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    padding: 20px;
    margin: 20px 0 0 0;
  }
  button .texto {
    align-items: flex-start;
    text-align: left;
    margin: 0 0 0 15px;
  }

  button .texto h3 {
    font-size: var(--f2);
  }

  button .texto p {
    font-size: var(--f1);
  }

  button .icone {
    width: 50px;
    height: 50px;
    border-radius: 10px;
  }

  button .icone svg {
    min-width: 20px;
    width: 20px;
    max-height: 20px;
  }
}
</style>
